.logic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
}
.logic .logic-left {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  z-index: 10;
}
.logic .logic-left .operate-title {
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  word-wrap: break-word;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  display: inline-block;
  white-space: nowrap;
  margin: 0;
  padding: 0;
  width: 65px;
  background-color: #f8f8fa;
  line-height: 28px;
  position: relative;
  z-index: 1;
  height: 28px;
}
.logic .logic-left .operate-title .mrg-title {
  text-align: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: block;
  margin-left: 11px;
  margin-right: 11px;
  line-height: 28px;
  height: 28px;
}
.logic .logic-left .operate-title .mrg-title i {
  font-size: 12px;
}
.logic .logic-left:hover .operate-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.logic .logic-left:hover .operate-title .mrg-title {
  margin-right: 0 !important;
}
.logic .logic-left .operate-icon {
  width: 40px;
  height: 28px;
  line-height: 28px;
  background-color: #f8f8fa;
  z-index: 1;
  display: none;
}
.logic .logic-left .operate-icon i {
  font-size: 12px;
  font-style: normal;
  display: unset;
  padding: 5px 3px;
  cursor: pointer;
  position: relative;
  z-index: 10;
}
.logic .logic-right-add {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 41.4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 26px;
}
.logic .logic-right-add .operand-btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-weight: 400;
  text-align: center;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  -webkit-appearance: button;
  cursor: pointer;
  height: 28px;
  padding: 0 10px;
  margin-right: 10px;
  font-size: 12px;
  color: #246dff;
  background: #fff;
  border: 1px solid #246dff;
  border-radius: 2px;
}