.api-card[data-v-2eed551a] {
  height: 128px;
  width: 600px;
  border-radius: 4px;
  border: 1px solid var(--deCardStrokeColor, #dee0e3);
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 20px 16px;
  font-family: PingFang SC;
}
.api-card .name[data-v-2eed551a] {
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.api-card .req-title[data-v-2eed551a],
.api-card .req-value[data-v-2eed551a] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 400;
}
.api-card .req-title[data-v-2eed551a] :nth-child(1),
.api-card .req-value[data-v-2eed551a] :nth-child(1) {
  width: 56px;
}
.api-card .req-title[data-v-2eed551a] :nth-child(2),
.api-card .req-value[data-v-2eed551a] :nth-child(2) {
  margin-left: 84px;
  max-width: 415px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.api-card .req-title[data-v-2eed551a] {
  color: var(--deTextPrimary, #1f2329);
  margin: 16px 0 4px 0;
}
.api-card .req-value[data-v-2eed551a] {
  color: var(--deTextSecondary, #646a73);
}
.api-card .de-copy-icon[data-v-2eed551a] {
  cursor: pointer;
  margin-right: 20px;
  color: var(--deTextSecondary, #646a73);
}
.api-card .de-delete-icon[data-v-2eed551a]:not(.not-allow) {
  cursor: pointer;
}
.api-card .de-delete-icon[data-v-2eed551a]:not(.not-allow):hover {
  color: var(--deDanger, #f54a45);
}
.api-card .de-tag[data-v-2eed551a] {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 2px;
  padding: 1px 6px;
  height: 24px;
}
.flex-space[data-v-2eed551a] {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.el-input-number[data-v-2eed551a] {
  width: 100%;
}
.el-input-number[data-v-2eed551a]  .el-input__inner {
  text-align: left;
}
.el-input-number[data-v-2eed551a] .el-input-number__decrease,
.el-input-number[data-v-2eed551a] .el-input-number__increase {
  background: transparent;
}
.el-input-number.is-disabled .el-input-number__decrease[data-v-2eed551a] {
  border-right-color: #e4e7ed;
}
.el-input-number.is-disabled .el-input-number__increase[data-v-2eed551a] {
  border-bottom-color: #e4e7ed;
}
.de-svg-in-table .select-type[data-v-2eed551a] {
  width: 180px;
}
.de-svg-in-table .select-type[data-v-2eed551a] .el-input__inner {
  padding-left: 32px;
}
.de-svg-in-table .select-svg-icon[data-v-2eed551a] {
  position: absolute;
  left: 24px;
  top: 15px;
}
.de-svg-in-table[data-v-2eed551a] .el-table__expand-icon .el-icon-arrow-right::before {
  content: "\E791" !important;
}
.schema-label[data-v-2eed551a] .el-form-item__label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.schema-label[data-v-2eed551a] .el-form-item__label::after {
  display: none;
}
.schema-label[data-v-2eed551a] .el-form-item__label .name .required::after {
  content: "*";
  color: #f54a45;
  margin-left: 2px;
}