.de-dataset-form .top {
  height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 24px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
.de-dataset-form .top .name {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50%;
  position: relative;
}
.de-dataset-form .top .name .el-input {
  min-width: 96px;
}
.de-dataset-form .top .name .el-input .el-input__inner {
  line-height: 24px;
  height: 24px;
}
.de-dataset-form .top .name i {
  cursor: pointer;
}
.de-dataset-form .top .oprate .table-num {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  margin-right: 16px;
  color: var(--deTextPrimary, #1f2329);
}
.de-dataset-form .container {
  width: 100%;
  height: calc(100vh - 56px);
}
.dataset-filed {
  height: 400px;
  overflow-y: auto;
}
.tree-select-dataset {
  display: none;
}