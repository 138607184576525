.de-kettle .el-input-number {
  width: 100%;
}
.de-kettle .el-input-number .el-input__inner {
  text-align: left;
}
.de-kettle .el-input-number .el-input-number__decrease,
.de-kettle .el-input-number .el-input-number__increase {
  background: transparent;
}
.de-tag {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 2px;
  padding: 1px 6px;
  height: 24px;
}