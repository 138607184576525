.de-date-pick {
  width: 256px;
  position: relative;
}
.de-date-pick .el-input__icon {
  display: none;
}
.de-date-pick .el-range-input {
  text-align: left;
}
.de-date-pick .el-date-editor {
  padding-left: 12px;
  width: 100% !important;
}
.de-date-pick .calendar-outlined {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 13px;
}