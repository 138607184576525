.tree-select {
  display: none !important;
}
.user-popper {
  background: #fff;
  padding: 0;
}
.user-popper .popper__arrow {
  display: none;
}
.user-popper.dept {
  height: 300px;
  overflow: auto;
}