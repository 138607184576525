
.my_table[data-v-44e1a4ca]  .el-table__row > td {
  /* 去除表格线 */
  border: none;
  padding: 0 0;
}
.my_table[data-v-44e1a4ca]  .el-table th.is-leaf {
  /* 去除上边框 */
  border: none;
}
.my_table[data-v-44e1a4ca]  .el-table::before {
  /* 去除下边框 */
  height: 0;
}
.de-root-class[data-v-44e1a4ca] {
  margin-top: 24px;
  text-align: right;
}
.preview[data-v-44e1a4ca] {
  margin-top: -12px;
  border: 1px solid #e6e6e6;
  height: 300px !important;
  overflow: auto;
  background-size: 100% 100% !important;
  border-radius: 4px;
}
.preview-show[data-v-44e1a4ca] {
  border-left: 1px solid #e6e6e6;
  height: 300px;
  background-size: 100% 100% !important;
}
