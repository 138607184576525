.none-scroll-bar::-webkit-scrollbar {
  display: none;
}
.db-container {
  width: 100%;
  max-height: 65vh;
  overflow-y: auto;
  margin-top: 3px;
  position: relative;
  z-index: 10;
}
.db-container .name {
  margin: 16px 0;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--deTextPrimary, #1F2329);
}
.db-container .item-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.db-container .db-card {
  height: 141px;
  width: 177.6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background: #ffffff;
  border: 1px solid #dee0e3;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-left: 16px;
}
.db-container .db-card img {
  width: 100%;
  height: 102px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.db-container .db-card .db-name {
  width: 100%;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 12px;
  border-top: 1px solid rgba(31, 35, 41, 0.15);
}
.db-container .db-card:hover {
  -webkit-box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
          box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
}
.db-container .marLeft {
  margin-left: 0;
}
.de-copy-icon {
  cursor: pointer;
  margin-right: 5px;
  color: var(--deTextSecondary, #646a73);
}