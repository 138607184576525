
.el-divider--horizontal[data-v-6b471de3] {
  margin: 12px 0;
}
.form-item[data-v-6b471de3] {
  margin-bottom: 6px;
}
.el-checkbox[data-v-6b471de3] {
  margin-bottom: 14px;
  margin-left: 0;
  margin-right: 14px;
}
.el-checkbox.is-bordered + .el-checkbox.is-bordered[data-v-6b471de3] {
  margin-left: 0;
}
.dataPreview[data-v-6b471de3]  .el-card__header {
  padding: 0 8px 12px;
}
.dataPreview[data-v-6b471de3]  .el-card__body {
  padding: 10px;
}
span[data-v-6b471de3] {
  font-size: 14px;
}
.panel-height[data-v-6b471de3] {
  height: calc(100vh - 56px - 15px - 26px - 25px - 43px);
}
.blackTheme .panel-height[data-v-6b471de3] {
  height: calc(100vh - 56px - 15px - 26px - 25px - 43px);
  border-color: var(--TableBorderColor) !important;
}
.span-number[data-v-6b471de3] {
  color: #0a7be0;
}
.table-count[data-v-6b471de3] {
  color: #606266;
}
