.dataset-on-time[data-v-44f81bda] {
  margin: 0;
  width: 100%;
  overflow: auto;
  background-color: var(--ContentBG, #fff);
  padding: 24px;
  height: 100%;
}
.table-container[data-v-44f81bda] {
  height: calc(100% - 50px);
}
.table-container-filter[data-v-44f81bda] {
  height: calc(100% - 110px);
}