.custom-tree-node[data-v-80b58f10] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 32px;
}
.tree-main[data-v-80b58f10] {
  overflow-y: auto;
}
.tree-head[data-v-80b58f10] {
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid var(--TableBorderColor, #e6e6e6);
  border-top: 1px solid var(--TableBorderColor, #e6e6e6);
  background-color: var(--SiderBG, #f7f8fa);
  font-size: 12px;
  color: var(--TableColor, #3d4d66);
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  padding: 0 12px;
}
.auth-span[data-v-80b58f10] {
  float: right;
  margin-left: 24px;
}
.auth-span-check[data-v-80b58f10] {
  float: right;
  margin-left: 64px;
}