
.el-header[data-v-24aa5e35] {
  background-color: var(--ContentBG, rgb(241, 243, 248));
  color: var(--TextActive, #333);
  line-height: 30px;
}
.limit-length-data[data-v-24aa5e35] {
  font-size: 12px;
  color: var(--TableColor, #3d4d66);
}
