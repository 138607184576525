.flex-template {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.flex-template .el-input {
  margin-right: 2px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}