.de-dataset-search {
  padding: 10px 24px;
  height: 100%;
  overflow-y: auto;
}
.de-dataset-search .main-area-input .el-input-group__append {
  width: 70px;
  background: transparent;
}
.de-dataset-search .main-area-input .el-input-group__append .el-input__inner {
  padding-left: 12px;
}
.de-dataset-search .title-css {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.de-dataset-search .el-icon-plus {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 17px;
  color: #646a73;
  cursor: pointer;
}
.de-dataset-search .el-icon-plus:hover {
  background: rgba(31, 35, 41, 0.1);
  border-radius: 4px;
}
.de-dataset-search .el-icon-plus:active {
  background: rgba(31, 35, 41, 0.2);
  border-radius: 4px;
}
.de-dataset-dropdown .el-dropdown-menu__item {
  height: 40px;
  color: var(--deTextPrimary, #1f2329);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 9px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
}
.de-dataset-dropdown .el-dropdown-menu__item .svg-icon {
  margin-right: 8.75px;
  width: 16.5px;
  height: 18px;
}
.de-dataset-dropdown .el-dropdown-menu__item:hover {
  background: rgba(31, 35, 41, 0.1);
  color: var(--deTextPrimary, #1f2329);
}
.de-dataset-dropdown .el-dropdown-menu__item.is-disabled {
  background: #BBBFC4;
  color: #fff;
}
.de-dataset-dropdown .de-top-border {
  border-top: 1px solid rgba(31, 35, 41, 0.15);
}