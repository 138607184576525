.img-main[data-v-6505198c] {
  border-radius: 4px;
  overflow-x: auto;
  overflow-y: auto;
  text-align: center;
  height: calc(100vh - 113px) !important;
}
.toolbar-icon-active[data-v-6505198c] {
  cursor: pointer;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 3px;
}
.toolbar-icon-active[data-v-6505198c]:active {
  color: #000;
  border-color: #3a8ee6;
  background-color: red;
  outline: 0;
}
.toolbar-icon-active[data-v-6505198c]:hover {
  background-color: rgba(31, 35, 41, 0.1);
  color: #3a8ee6;
}
.icon20[data-v-6505198c] {
  font-size: 20px;
  color: var(--TextPrimary, #1F2329);
}
.icon16[data-v-6505198c] {
  font-size: 16px !important;
  color: var(--TextPrimary, #1F2329);
}
.text16[data-v-6505198c] {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--TextPrimary, #1F2329);
}
.margin-left12[data-v-6505198c] {
  margin-left: 12px !important;
}
.margin-left20[data-v-6505198c] {
  margin-left: 12px !important;
}