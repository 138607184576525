.driver-detail[data-v-6db1c53e] {
  font-family: PingFang SC;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.driver-detail .name[data-v-6db1c53e] {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  color: var(--deTextPrimary, #1f2329);
}
.driver-detail .descript[data-v-6db1c53e] {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-top: 4px;
  color: var(--deTextSecondary, #646a73);
}
.driver-detail .el-upload[data-v-6db1c53e] {
  margin-top: -4px;
}
.driver-detail .tips[data-v-6db1c53e] {
  margin: 2px 0 8px 0;
  color: var(----deTextPlaceholder, #8f959e);
}
.driver-detail .jar-cont[data-v-6db1c53e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 100%;
}
.driver-detail .jar-cont .jar-info[data-v-6db1c53e] {
  border: 1px solid var(--deCardStrokeColor, #dee0e3);
  border-radius: 4px;
  padding: 8px 12px;
  margin: 0 12px 12px 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 340px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.driver-detail .jar-cont .jar-info[data-v-6db1c53e]:hover {
  border: 1px solid var(--primary, #3370ff);
}
.driver-detail .jar-cont .jar-info img[data-v-6db1c53e] {
  height: 42px;
  width: 40px;
}
.driver-detail .jar-cont .jar-info .name-descript[data-v-6db1c53e] {
  margin: 0;
  margin-left: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.driver-detail .jar-cont .jar-info .name-descript .jar-descript[data-v-6db1c53e] {
  font-size: 14px;
  color: var(--deTextPlaceholder, #8f959e);
}
.driver-detail .jar-cont .jar-info .name-descript .jar-name[data-v-6db1c53e] {
  font-size: 12px;
  color: var(--deTextPrimary, #1f2329);
}
.driver-detail .jar-cont .jar-info .el-icon-delete[data-v-6db1c53e] {
  cursor: pointer;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  font-size: 13.3px;
  color: var(--deTextPlaceholder, #8f959e);
  position: absolute;
  top: 18px;
  right: 17px;
}
.driver-detail .jar-cont .jar-info .el-icon-delete[data-v-6db1c53e]:hover {
  background: rgba(31, 35, 41, 0.1);
  border-radius: 4px;
}