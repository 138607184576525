.top-list[data-v-9930dde6] {
  display: inline-block !important;
  height: 210px;
}
.aside-list-filter-active[data-v-9930dde6] {
  height: calc(100vh - 250px);
}
.template-main-preview[data-v-9930dde6] {
  margin-top: 12px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 2px 0 rgba(31, 31, 31, 0.15), 0 1px 2px 0 rgba(31, 31, 31, 0.15);
          box-shadow: 0 0 2px 0 rgba(31, 31, 31, 0.15), 0 1px 2px 0 rgba(31, 31, 31, 0.15);
  border: solid 2px #fff;
  padding: 24px;
  min-height: calc(100vh - 147px);
  background-color: var(--ContentBG, #ffffff);
}
.preview-slider[data-v-9930dde6] {
  width: calc(100% - 24px);
  overflow-x: auto;
  overflow-y: hidden;
}
.market-main[data-v-9930dde6] {
  padding: 24px;
}
.title-left[data-v-9930dde6] {
  float: left;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.title-right[data-v-9930dde6] {
  float: right;
  width: 320px;
}
.dialog-footer-self[data-v-9930dde6] {
  text-align: center;
}
.search-button-self[data-v-9930dde6] {
  text-align: left;
  padding-left: 10px;
}
.topbar-icon-active[data-v-9930dde6] {
  cursor: pointer;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 3px;
  font-size: 22px;
  background-color: rgb(245, 245, 245);
}
.topbar-icon-active[data-v-9930dde6]:active {
  color: #000;
  border-color: #3a8ee6;
  background-color: red;
  outline: 0;
}
.topbar-icon-active[data-v-9930dde6]:hover {
  background-color: rgba(31, 35, 41, 0.1);
  color: #3a8ee6;
}
.custom-position[data-v-9930dde6] {
  height: 70vh;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  color: #646A73;
  font-weight: 400;
}
.aside-active[data-v-9930dde6] {
  width: 206px;
  height: calc(100vh - 56px);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--ContentBG, #ffffff);
}
.aside-inActive[data-v-9930dde6] {
  position: relative;
  width: 0px;
}
.main-area-active[data-v-9930dde6] {
  width: calc(100% - 206px) !important;
}
.main-area[data-v-9930dde6] {
  width: 100%;
  padding: 24px;
  text-align: center;
  height: calc(100vh - 56px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.title-name-search[data-v-9930dde6] {
  width: 140px;
  float: left;
}
.icon20[data-v-9930dde6] {
  font-size: 20px !important;
}
.main-title[data-v-9930dde6] {
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--TextPrimary, #1F2329);
}
.insert-filter[data-v-9930dde6] {
  display: inline-block;
  font-weight: 400 !important;
  font-family: PingFang SC;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: var(--TextPrimary, #1F2329);
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 3px;
}
.insert-filter[data-v-9930dde6]:active {
  color: #000;
  border-color: #3a8ee6;
  background-color: red;
  outline: 0;
}
.insert-filter[data-v-9930dde6]:hover {
  background-color: rgba(31, 35, 41, 0.1);
  color: #3a8ee6;
}
.insert[data-v-9930dde6] {
  display: inline-block;
  font-weight: 400 !important;
  font-family: PingFang SC;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  color: #646A73;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 3px;
}
.insert[data-v-9930dde6]:active {
  color: #000;
  border-color: #3a8ee6;
  background-color: red;
  outline: 0;
}
.insert[data-v-9930dde6]:hover {
  background-color: rgba(31, 35, 41, 0.1);
  color: #3a8ee6;
}
.template-title[data-v-9930dde6] {
  float: left;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
  color: var(--TextPrimary, #1F2329);
}
.margin-top16[data-v-9930dde6] {
  margin-top: 16px;
}
.img-main[data-v-9930dde6] {
  border-radius: 4px;
  background: #0F1114;
  overflow-x: auto;
  overflow-y: hidden;
  height: calc(100% - 50px) !important;
}
.open-button[data-v-9930dde6] {
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 2;
}
.open-button[data-v-9930dde6]:hover {
  color: #3a8ee6;
}
.filter-icon-span[data-v-9930dde6] {
  float: left;
  border: 1px solid #DCDFE6;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  padding: 7px;
  margin-left: 8px;
}
.filter-icon-active[data-v-9930dde6] {
  border: 1px solid #3370FF;
  color: #3370FF;
}
.filter-icon-active[data-v-9930dde6] {
  border: 1px solid #3370FF;
  color: #3370FF;
}
.search-area[data-v-9930dde6] {
  width: 100%;
  position: relative;
}
.preview-outer-body[data-v-9930dde6] {
  width: 100%;
  height: calc(100vh - 56px);
  background-color: var(--MainBG, #f5f6f7);
}
.market-main[data-v-9930dde6] {
  padding: 24px;
}
.title-left[data-v-9930dde6] {
  float: left;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--TextPrimary, #1F2329);
}
.log-area[data-v-9930dde6] {
  height: calc(100vh - 420px);
}
.ds-from-main[data-v-9930dde6] {
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 2px 0 rgba(31, 31, 31, 0.15), 0 1px 2px 0 rgba(31, 31, 31, 0.15);
          box-shadow: 0 0 2px 0 rgba(31, 31, 31, 0.15), 0 1px 2px 0 rgba(31, 31, 31, 0.15);
  border: solid 2px #fff;
  height: 77vh;
  background-color: var(--ContentBG, #ffffff);
}