.consanguinity {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--MainBG, #f5f6f7);
  overflow: hidden;
  padding: 24px 24px 24px 24px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.consanguinity .active-icon {
  color: var(--primary, #3370ff);
}
.consanguinity .consanguinity-table {
  height: calc(100% - 100px);
  width: 100%;
}
.consanguinity .route-title {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--TextPrimary, #1f2329);
  width: 100%;
  margin: 0;
}
.consanguinity .container-wrapper {
  width: 100%;
  overflow: auto;
  background-color: var(--ContentBG, #ffffff);
  margin-top: 24px;
  padding: 24px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.consanguinity .container-wrapper .select-icon {
  margin-bottom: 16px;
}
.consanguinity .container-wrapper .select-icon i,
.consanguinity .container-wrapper .select-icon svg {
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
}
.custom-tree-node {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.custom-tree-node-list {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding: 0 8px;
}
.father .child {
  /*display: none;*/
  visibility: hidden;
}
.father:hover .child {
  /*display: inline;*/
  visibility: visible;
}
.relation-popover {
  max-height: 70%;
  overflow-y: scroll;
}
