.info-tab[data-v-f488cc14] {
  width: 100%;
  padding: 0 4px;
  font-family: PingFang SC;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.info-tab .title-type[data-v-f488cc14] {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--deTextPrimary, #1f2329);
}
.info-tab .info-card[data-v-f488cc14] {
  padding-bottom: 4px;
  border-bottom: 1px solid rgba(31, 35, 41, 0.15);
  margin-bottom: 12px;
}
.info-tab .info-card .info-item[data-v-f488cc14] {
  font-family: PingFang SC;
  font-weight: 400;
  margin: 6px 0 12px 0;
}
.info-tab .info-card .info-title[data-v-f488cc14] {
  margin: 0 !important;
  font-weight: 600;
  font-size: 12px;
}
.info-tab .info-card .info-content[data-v-f488cc14] {
  font-size: 12px;
  margin: 0 !important;
}
.info-tab[data-v-f488cc14] :last-child {
  border: none;
}