
.custom-tree-node[data-v-07fac597] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    font-size: 14px;
    padding-left: 8px;
}
.tree-main[data-v-07fac597]{
    overflow-y: auto;
}
.blackTheme .tree-main[data-v-07fac597] {
    border-color: var(--TableBorderColor) !important;
}
  /* .tree-head{
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #f7f8fa;
    font-size: 12px;
    color: #3d4d66 ;
  } */
.tree-head[data-v-07fac597]{
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid var(--TableBorderColor, #e6e6e6);
    background-color: var(--SiderBG, #f7f8fa);
    font-size: 12px;
    color: var(--TableColor, #3d4d66) ;
}
.auth-span[data-v-07fac597]{
    float: right;
    width:50px;
    margin-right: 30px
}
.highlights-text[data-v-07fac597] {
    color: #faaa39 !important;
}

