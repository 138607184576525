.about-card[data-v-584f3c8e] {
  background: inherit;
  margin-top: 5%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 640px;
  min-width: 640px;
  height: auto;
  position: relative;
}
.about-card[data-v-584f3c8e]  div.el-card__header {
  padding: 0;
}
.form-header[data-v-584f3c8e] {
  line-height: 60px;
  font-size: 18px;
}