.demo_main[data-v-6d1f68be] {
  height: 100%;
  padding: 10px 20px 10px 20px;
}
.demo_title[data-v-6d1f68be] {
  float: left;
  height: 100%;
  width: 50%;
  background-size: 100% 100% !important;
  background-image: url(../img/deV.f63b7ecc.png);
}
.demo_content[data-v-6d1f68be] {
  margin: auto;
  padding-left: 10px;
  float: left;
  width: 50%;
  font-weight: 500;
  color: var(--MenuActiveBG, #409EFF);
}