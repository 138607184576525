.organization[data-v-33addb49] {
  height: 100%;
  background-color: var(--MainBG, #f5f6f7);
}
.organization .tabs-container[data-v-33addb49] {
  height: calc(100% - 48px);
  background: var(--ContentBG, #ffffff);
  overflow-x: auto;
}
.organization .tabs-container .msg-cont[data-v-33addb49] {
  padding: 24px;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}