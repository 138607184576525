
.ms-aside-container[data-v-3ee1d4d6] {
    height: calc(100vh - 91px);
    padding: 15px;
    min-width: 60px;
    max-width: 60px;
    border: none;
}
.ms-main-container[data-v-3ee1d4d6] {
    height: calc(100vh - 91px);
    background: #fff;
}
.de-header[data-v-3ee1d4d6] {
    height: 35px !important;
    border-bottom: 1px solid #E6E6E6;
}
.showLeftPanel[data-v-3ee1d4d6] {
  overflow: hidden;
  position: relative;
  width: calc(100% - 15px);
}
