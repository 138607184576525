.template-main-content[data-v-5d46d865] {
  height: calc(100vh - 190px) !important;
}
.template-main[data-v-5d46d865] {
  text-align: center;
  border-radius: 4px;
  padding: 0 12px 24px 12px;
  height: calc(100vh - 190px) !important;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--ContentBG, #ffffff);
}
.market-main[data-v-5d46d865] {
  padding: 24px;
}
.title-left[data-v-5d46d865] {
  float: left;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--TextPrimary, #1F2329);
}
.title-right[data-v-5d46d865] {
  float: right;
  width: 320px;
}
.dialog-footer-self[data-v-5d46d865] {
  text-align: right;
}
.search-button-self[data-v-5d46d865] {
  text-align: left;
  padding-left: 10px;
}
.topbar-icon-active[data-v-5d46d865] {
  cursor: pointer;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  border-radius: 3px;
  font-size: 22px;
  background-color: rgb(245, 245, 245);
}
.topbar-icon-active[data-v-5d46d865]:active {
  color: #000;
  border-color: #3a8ee6;
  background-color: red;
  outline: 0;
}
.topbar-icon-active[data-v-5d46d865]:hover {
  background-color: rgba(31, 35, 41, 0.1);
  color: #3a8ee6;
}
.custom-position[data-v-5d46d865] {
  height: 80vh;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  color: #646A73;
  font-weight: 400;
}
.outer-body[data-v-5d46d865] {
  width: 100%;
  height: calc(100vh - 56px);
  background-color: var(--MainBG, #f5f6f7);
}
.market-dialog-css[data-v-5d46d865]  .el-form-item__label {
  width: 100% !important;
  text-align: left;
}
.market-dialog-css[data-v-5d46d865]  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}
.market-dialog-css[data-v-5d46d865]  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
  content: "*";
  color: #f54a45;
  margin-left: 2px;
}
.market-dialog-css[data-v-5d46d865]  .el-form-item__content {
  margin-left: 0 !important;
}
.market-dialog-css[data-v-5d46d865]  .vue-treeselect__input {
  vertical-align: middle;
}
.main-log-area[data-v-5d46d865] {
  position: relative;
  padding: 24px;
}
.log-area[data-v-5d46d865] {
  height: calc(100vh - 240px);
}