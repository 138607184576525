.user-editer-form[data-v-5a92b880]  .el-dialog__body {
  padding: 0 24px 24px 24px;
}
.user-editer-form[data-v-5a92b880]  .el-dialog__header {
  padding: 24px 24px 16px 24px;
}
.user-editer-form[data-v-5a92b880]  .el-dialog__footer {
  padding-top: 0;
}
.user-editer-form .editer-form-title[data-v-5a92b880] {
  width: 100%;
  border-radius: 4px;
  background: #e1eaff;
  padding: 9px 16px;
  margin-bottom: 16px;
}
.user-editer-form .editer-form-title i[data-v-5a92b880] {
  color: #3370ff;
  font-size: 14.6666660309px;
}
.user-editer-form .editer-form-title .pwd[data-v-5a92b880],
.user-editer-form .editer-form-title .btn-text[data-v-5a92b880] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.user-editer-form .editer-form-title .pwd[data-v-5a92b880] {
  margin: 0 8px;
  color: #1f2329;
}
.user-editer-form .editer-form-title .btn-text[data-v-5a92b880] {
  padding: 0;
  border: none;
}
.user-editer-form[data-v-5a92b880]  .el-form-item__label {
  width: 100% !important;
  text-align: left;
}
.user-editer-form[data-v-5a92b880]  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}
.user-editer-form[data-v-5a92b880]  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
  content: "*";
  color: #f54a45;
  margin-left: 2px;
}
.user-editer-form[data-v-5a92b880]  .el-form-item__content {
  margin-left: 0 !important;
}
.user-editer-form .input-with-select[data-v-5a92b880]  .el-input-group__prepend {
  background-color: #fff;
}
.user-editer-form .input-with-select .el-select[data-v-5a92b880]  .el-input__inner {
  width: 72px;
}
.user-editer-form .de-form-gender-select[data-v-5a92b880] {
  width: 100%;
}