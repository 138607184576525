.ds-table-detail {
  height: 100%;
  padding: 10px 14px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.ds-table-detail .el-tabs-content {
  height: calc(100% - 56px);
}
.ds-table-detail .ds-top {
  height: 36px;
  text-align: right;
}
.ds-table-detail .w600 {
  padding-top: 0 !important;
}
.ds-table-detail .de-ds-cont {
  padding: 0 !important;
  background: transparent !important;
}
