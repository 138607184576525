.top-div-class[data-v-782a0e23] {
  max-height: calc(100vh - 335px);
  width: 100%;
  position: fixed;
  overflow-y: auto;
}
.detail-class[data-v-782a0e23] {
  width: 300px;
  position: fixed;
  bottom: 0px;
}
.view-list-thumbnails[data-v-782a0e23] {
  width: 100%;
  height: 100%;
}
.father .child[data-v-782a0e23] {
  /*display: none;*/
  visibility: hidden;
}
.father:hover .child[data-v-782a0e23] {
  /*display: inline;*/
  visibility: visible;
}
.custom-tree-node-list[data-v-782a0e23] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding: 0 8px;
}