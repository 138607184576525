.card_main[data-v-3e4d3b2a] {
  padding: 40px 30px 30px 30px;
  height: 33vh;
}
.card_head[data-v-3e4d3b2a] {
  padding-left: 15px;
  color: white;
  line-height: 30px;
  font-weight: bold;
  font-size: 18px;
  background-color: var(--MenuActiveBG, #409EFF);
}
.triangle[data-v-3e4d3b2a] {
  width: 0;
  height: 0;
  border-bottom: 30px solid var(--MenuActiveBG, #409EFF);
  border-right: 30px solid transparent;
  -webkit-transform: rotate(180deg);
  transform: rotate(0deg);
}
.card_content[data-v-3e4d3b2a] {
  border: lightgray solid 1px;
  border-radius: 0px 5px 5px 5px;
  height: 100%;
  overflow-y: auto;
}