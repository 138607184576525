.union-container[data-v-45aab2c6] {
  height: 275px;
  font-family: PingFang SC;
}
.union-header[data-v-45aab2c6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 8px;
  color: var(--deTextPrimary, #1f2329);
  font-size: 16px;
  font-weight: 500;
}
.union-header-operator[data-v-45aab2c6] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
}
.union-header-operator .select-svg-icon[data-v-45aab2c6] {
  position: absolute;
  left: 12px;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.union-selector[data-v-45aab2c6] {
  width: 180px;
}
.union-selector[data-v-45aab2c6] .el-input__inner {
  padding-left: 32px;
}
.union-add[data-v-45aab2c6] {
  margin-left: 12px;
}
.union-body[data-v-45aab2c6] {
  height: 240px;
  width: 100%;
}
.union-body-header[data-v-45aab2c6] {
  height: 46px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: var(--deTextSecondary, #646a73);
}
.union-body-header .column[data-v-45aab2c6] {
  width: 336px;
  display: inline-block;
  margin-right: 9px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 10px;
}
.union-body-header .column-last[data-v-45aab2c6] {
  width: 40px;
  text-align: center;
}
.union-body-container[data-v-45aab2c6] {
  height: 180px;
  overflow-y: auto;
}
.select-field[data-v-45aab2c6] {
  width: 352px;
  display: inline-block;
}
.union-body-item[data-v-45aab2c6] {
  height: 32px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.union-body-item .column[data-v-45aab2c6] {
  width: 352px;
  display: inline-block;
  margin-right: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.union-body-item .column-last[data-v-45aab2c6] {
  width: 40px;
  text-align: center;
}
.union-body-item .column-last[data-v-45aab2c6]  i {
  font-size: 16px;
  color: var(--deTextSecondary, #646a73);
}