
.node-container[data-v-0507b262] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.join-icon[data-v-0507b262] {
  height: 26px;
  font-size: 24px;
  line-height: 26px;
  position: absolute;
  left: 18px;
  color: #dcdfe6;
}
.join-svg-container[data-v-0507b262] {
  width: 60px;
}
.node-line[data-v-0507b262] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.join-icon[data-v-0507b262]:hover {
  cursor: pointer;
  color: var(--Main, #2681ff);
}
