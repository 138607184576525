.main_outer[data-v-15fcc570] {
  background-color: var(--MainBG, #f5f6f7);
}
.main_container[data-v-15fcc570] {
  min-width: 1250px;
  padding: 0 24px 0 24px;
  overflow: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-size: 100% 444px !important;
  background: url(../img/wizard_main_bg.98203fb9.png) no-repeat;
}
.main_content[data-v-15fcc570] {
  width: 1200px;
}
.content_top_banner[data-v-15fcc570] {
  color: var(--ContentBG, #FFFFFF);
  position: relative;
  width: 100%;
  height: 230px;
}
.top_banner_content[data-v-15fcc570] {
  position: absolute;
  top: 62px;
  height: 230px;
}
.top_banner_img[data-v-15fcc570] {
  position: absolute;
  width: 520px;
  height: 230px;
  top: 0;
  right: 50px;
}
.top_banner_card[data-v-15fcc570] {
  position: relative;
  width: 100%;
  height: 214px;
}
.hint_head[data-v-15fcc570] {
  line-height: 48px;
  font-weight: 600;
  font-size: 48px;
}
.hint_content[data-v-15fcc570] {
  margin-top: 12px;
  line-height: 26px;
  font-weight: 400;
  font-size: 18px;
}
.content_middle[data-v-15fcc570] {
  height: 290px;
  width: 100%;
  margin-top: 24px;
}
.content_middle_left[data-v-15fcc570] {
  float: left;
  width: 792px;
  height: 290px;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--ContentBG, #FFFFFF);
}
.content_middle_title[data-v-15fcc570] {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  color: var(--TextPrimary, #1F2329);
}
.content_middle_more[data-v-15fcc570] {
  float: right;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #646A73;
  border-radius: 4px;
  height: 26px;
  padding: 2px;
}
.content_middle_more[data-v-15fcc570]:hover {
  background: rgba(31, 35, 41, 0.1);
  cursor: pointer;
}
.content_middle_more i[data-v-15fcc570]:hover {
  background: none;
}
.content_middle_more a[data-v-15fcc570]:hover {
  background: none;
}
.content_middle_right[data-v-15fcc570] {
  float: left;
  height: 290px;
  width: 384px;
  margin-left: 24px;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--ContentBG, #FFFFFF);
}
.content_middle_left[data-v-15fcc570] {
  float: left;
  width: 792px;
  height: 290px;
  padding: 24px;
  border-radius: 4px;
  background-color: var(--ContentBG, #FFFFFF);
}
.li-custom[data-v-15fcc570] {
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  list-style-type: disc;
  list-style-position: inside;
  border-radius: 4px;
  padding-left: 12px;
  margin-left: -12px;
}
.li-custom[data-v-15fcc570]:hover {
  background: rgba(31, 35, 41, 0.1);
  cursor: pointer;
  color: #3370FF !important;
}
.li-custom:hover .li-a[data-v-15fcc570] {
  color: #3370FF !important;
}
.li-custom a[data-v-15fcc570]:hover {
  background: none;
}
.li-a[data-v-15fcc570] {
  color: var(--TextPrimary, #1F2329);
}
.ul-custom[data-v-15fcc570] {
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  color: #8F959E;
}
.content_bottom[data-v-15fcc570] {
  width: 100%;
  height: 208px;
}
.content_bottom_contact[data-v-15fcc570] {
  float: left;
  margin-left: 278px;
  width: 300px;
  margin-top: 40px;
}
.contact_title[data-v-15fcc570] {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--TextPrimary, #1F2329);
  margin-bottom: 16px;
}
.contact_content[data-v-15fcc570] {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #646A73;
  margin-top: 8px;
}
.contact_content a[data-v-15fcc570]:hover {
  color: #3370FF;
}
.content_bottom_qr_code[data-v-15fcc570] {
  width: 400px;
  float: right;
  text-align: right;
  margin-right: 180px;
  margin-top: 40px;
}
.contact_title_qr[data-v-15fcc570] {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #646A73;
  margin-bottom: 8px;
}
.contact_wechat_train[data-v-15fcc570] {
  width: 100px;
  float: right;
}
.contact_wechat_train_img[data-v-15fcc570] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100px;
  height: 100px;
  border: 2px solid #FFFFFF;
}
.contact_wechat_official[data-v-15fcc570] {
  width: 100px;
  float: right;
  margin-right: 40px;
}
.contact_wechat_official_img[data-v-15fcc570] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100px;
  height: 100px;
  border: 2px solid #FFFFFF;
}
.contact_wechat_group[data-v-15fcc570] {
  width: 100px;
  float: right;
  margin-right: 40px;
}
.contact_wechat_group_img[data-v-15fcc570] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100px;
  height: 100px;
  border: 2px solid #FFFFFF;
}
.main_container_outer[data-v-15fcc570] {
  width: 100%;
  height: calc(100vh - 56px);
  background-color: var(--MainBG, #f5f6f7);
  overflow: auto;
}