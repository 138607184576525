
.textarea[data-v-0b620db0] {
  margin-top: 10px;
}
.ms-body[data-v-0b620db0] {
  padding: 15px 0;
  height: 400px;
}
.el-dropdown[data-v-0b620db0] {
  margin-left: 20px;
  line-height: 30px;
}
.ace_editor[data-v-0b620db0] {
  border-radius: 5px;
}
.el-radio-group[data-v-0b620db0] {
  margin: 10px 10px;
  margin-top: 15px;
}
.ms-el-link[data-v-0b620db0] {
  float: right;
  margin-right: 45px;
}
