
.avatar-uploader[data-v-1498b90c]  .el-upload {
  width: 100px;
  height: 60px;
  line-height: 70px;
}
.avatar-uploader[data-v-1498b90c]  .el-upload-list li{
  width: 100px !important;
  height: 60px !important;
}
.disabled[data-v-1498b90c]  .el-upload--picture-card {
  display: none;
}
.shape-item[data-v-1498b90c]{
  padding: 6px;
  border: none;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.form-item-slider[data-v-1498b90c]  .el-form-item__label{
  font-size: 12px;
  line-height: 38px;
}
.form-item[data-v-1498b90c]  .el-form-item__label{
  font-size: 12px;
}
.el-select-dropdown__item[data-v-1498b90c]{
  padding: 0 20px;
}
span[data-v-1498b90c]{
  font-size: 12px
}
.el-form-item[data-v-1498b90c]{
  margin-bottom: 6px;
}
