.about-card[data-v-114e3f8a] {
  background: inherit;
  margin-top: 5%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 640px;
  min-width: 640px;
  height: 400px;
  position: relative;
}
.about-card[data-v-114e3f8a]  div.el-card__header {
  padding: 0;
}
.about-card-medium[data-v-114e3f8a] {
  height: 415px !important;
}
.about-card-max[data-v-114e3f8a] {
  height: 430px !important;
}
.license-header[data-v-114e3f8a] {
  height: 100px;
  background-image: url(../img/license_header.f7f2f512.png);
  text-align: center;
  padding: 20px 0;
  background-size: 100% 100%;
}
.license-content[data-v-114e3f8a] {
  font-size: 16px;
  padding: 50px;
}
.license-content[data-v-114e3f8a]  table {
  width: 100%;
}
.license-content[data-v-114e3f8a]  th {
  text-align: left;
  width: 45%;
}
.license-content[data-v-114e3f8a]  td {
  display: table-cell;
  vertical-align: inherit;
}
.md-padding[data-v-114e3f8a] {
  padding: 10px;
}
.lic_rooter[data-v-114e3f8a] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-line-pack: center;
      align-content: center;
  max-width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.lic_rooter[data-v-114e3f8a]  a {
  color: rgb(10, 123, 224);
  cursor: pointer;
}