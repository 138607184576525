.layout-container[data-v-02e779e2] {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: var(--ContentBG);
  overflow: auto;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background-color: var(--MainBG, #f5f6f7);
  overflow: hidden;
  padding: 0 24px 0 24px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
.layout-container .route-title[data-v-02e779e2] {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--TextPrimary, #1F2329);
  width: 100%;
  margin: 0;
}
.layout-container .container-wrapper[data-v-02e779e2] {
  width: 100%;
  overflow: auto;
  background-color: #fff;
  margin-top: 24px;
  padding: 24px;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.layout-container .layout-inner-padding[data-v-02e779e2] {
  padding: 0;
  margin-top: 16px;
  overflow: hidden;
}