.table-container[data-v-1930a3c4] {
  height: calc(100% - 50px);
}
.table-container .mar16[data-v-1930a3c4] {
  margin: 0 -2px 0 4px;
}
.table-container .mr2[data-v-1930a3c4] {
  margin-left: -3px;
}
.table-container-filter[data-v-1930a3c4] {
  height: calc(100% - 110px);
}