.list-columns-select {
  padding: 8px 11px !important;
  width: 238px;
}
.list-columns-select .title,
.list-columns-select .el-checkbox {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
  margin: 0;
  color: #8f959e;
}
.list-columns-select .el-checkbox {
  color: #1f2329;
  width: 100%;
}
.de-card-dropdown {
  margin-top: 0 !important;
}
.de-card-dropdown .popper__arrow {
  display: none !important;
}