.de-el-dropdown-menu .dimension {
  padding: 0;
}
.de-el-dropdown-menu .dimension li {
  font-family: Alibaba-PuHuiTi-Regular, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  list-style: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  white-space: nowrap;
  cursor: pointer;
  -webkit-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  overflow: hidden;
  font-size: 12px;
  text-overflow: ellipsis;
  padding: 0 16px 0 28px;
  line-height: 32px;
  height: 32px;
  margin: 0;
  padding-left: 16px;
  color: rgba(0, 0, 0, 0.65);
}
.de-el-dropdown-menu .dimension li:hover {
  color: #2e74ff;
  background-color: #f0f7ff;
}
.de-el-dropdown-menu .el-input__inner {
  font-family: inherit;
  overflow: visible;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  display: inline-block;
  width: 100%;
  height: 28px;
  padding: 4px 7px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 28px;
  background-color: #fff;
  background-image: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-appearance: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  text-overflow: ellipsis;
  position: relative;
  text-align: inherit;
  min-height: 100%;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0;
  padding-left: 26px;
}
.de-el-dropdown-menu .el-input__inner:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(46, 116, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(46, 116, 255, 0.2);
  border-right-width: 1px !important;
  outline: 0;
  border-color: none;
}
.de-el-dropdown-menu .el-input {
  font-family: Alibaba-PuHuiTi-Regular, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: start;
  padding: 0 6px;
}
.de-el-dropdown-menu-fixed .de-panel {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  padding: 0;
  width: 360px;
  max-width: 600px;
  min-height: 30px;
  background-color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.de-el-dropdown-menu-fixed .de-panel .mod-left {
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  vertical-align: top;
  padding: 5px;
  width: 50%;
  height: 300px;
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.de-el-dropdown-menu-fixed .de-panel .mod-left .el-input__inner {
  font-family: inherit;
  overflow: visible;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 28px;
  background-color: #fff;
  background-image: none;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-appearance: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  text-overflow: ellipsis;
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 59%, 0.1);
  border: 1px solid hsla(0, 0%, 59%, 0.1);
  height: 30px;
  padding: 0 8px;
  outline: 0;
}
.de-el-dropdown-menu-fixed .de-panel .mod-left .el-input__inner:focus {
  -webkit-box-shadow: 0 0 0 2px rgba(46, 116, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(46, 116, 255, 0.2);
  border-right-width: 1px !important;
  outline: 0;
  border-color: none;
}
.de-el-dropdown-menu-fixed .de-panel .right-de {
  border-left: 1px solid hsla(0, 0%, 59%, 0.1);
}
.de-el-dropdown-menu-fixed .de-panel .autochecker-list {
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  height: 221px;
  position: relative;
  padding: 0;
}
.de-el-dropdown-menu-fixed .de-panel .autochecker-list li {
  direction: ltr;
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #333;
  white-space: nowrap;
  list-style: none;
  line-height: 28px;
  height: 28px;
  width: 100%;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.de-el-dropdown-menu-fixed .de-panel .autochecker-list li:hover {
  background-color: #f8f8fa;
  color: #2153d4;
  opacity: 1;
}
.de-el-dropdown-menu-fixed .de-panel .autochecker-list li:hover span {
  display: block;
}
.de-el-dropdown-menu-fixed .de-panel .autochecker-list li i {
  color: #333;
  font-size: 12px;
  cursor: pointer;
  vertical-align: top;
  line-height: 28px;
  height: 28px;
  display: inline-block;
  opacity: 0;
}
.de-el-dropdown-menu-fixed .de-panel .autochecker-list li label {
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  font-size: 12px;
  direction: ltr;
  color: #333;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  width: 87%;
  height: 28px;
  line-height: 14px;
  padding: 8px 20px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.de-el-dropdown-menu-fixed .de-panel .autochecker-list li span {
  display: none;
  position: absolute;
  width: 14px;
  height: 14px;
  line-height: 11px;
  top: 6px;
  right: 5px;
  font-size: 15px;
  cursor: pointer;
  background: #2153d4;
  color: #fff;
  text-align: center;
  border-radius: 999px;
}
.de-el-dropdown-menu-fixed .de-panel .select-all {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  overflow: visible;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  padding: 0 15px;
  font-size: 12px;
  outline: 0;
  color: #fff;
  border-color: #2e74ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  -webkit-appearance: button;
  cursor: pointer;
  border-radius: 0;
  background: #2153d4;
  padding-left: 5px;
  text-align: center;
  display: inline-block;
  width: 100%;
  height: 25px;
}
.de-el-dropdown-menu-fixed .de-panel .select-all:hover {
  border: 1px solid transparent;
  background: #4794ff;
  color: #fff;
}
.de-el-dropdown-menu-fixed .de-panel .right-top {
  color: rgba(0, 0, 0, 0.65);
  text-align: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  zoom: 1;
  border-bottom: 1px solid #f8f8fa;
  height: 30px;
  width: 100%;
  font-size: 12px;
  line-height: 35px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.de-el-dropdown-menu-fixed .de-panel .right-top .right-btn {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  z-index: 10;
  float: right;
  cursor: pointer;
  line-height: 25px;
  padding: 0 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: rgba(70, 140, 255, 0.1);
  width: 75px;
}
.de-el-dropdown-menu-fixed .de-panel .right-menu-foot {
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 30px;
  text-align: right;
  line-height: 30px;
  margin-top: 5px;
  border-top: 1px solid hsla(0, 0%, 59%, 0.1);
}
.de-el-dropdown-menu-fixed .de-panel .right-menu-foot .footer-left {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  float: left;
}
.de-el-dropdown-menu-fixed .de-panel .right-menu-foot .footer-right {
  float: right;
  padding-left: 10px;
  cursor: pointer;
}
.de-el-dropdown-menu-fixed .de-panel .right-menu-foot .confirm-btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  height: 28px;
  padding: 0 15px;
  font-size: 12px;
  border-radius: 2px;
  outline: 0;
  color: #fff;
  background-color: #2e74ff;
  border-color: #2e74ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  -webkit-appearance: button;
  cursor: pointer;
}
.de-el-dropdown-menu-manu {
  padding: 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  position: absolute;
  top: 20px;
  width: 150px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  right: 0;
}
.de-el-dropdown-menu-manu .text-area {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 220px;
  position: relative;
}
.de-el-dropdown-menu-manu .text-area .input {
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  overflow: auto;
  resize: vertical;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  background-color: #fff;
  background-image: none;
  max-width: 100%;
  min-height: 28px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
  text-overflow: ellipsis;
  outline: 0;
  padding: 5px 5px 28px;
  width: 100%;
  height: 220px;
  border-radius: 0;
  border: none;
  -webkit-box-shadow: 0 2px 6px 0 rgba(130, 150, 183, 0.72);
          box-shadow: 0 2px 6px 0 rgba(130, 150, 183, 0.72);
}
.de-el-dropdown-menu-manu .text-area .text-area-btn {
  position: absolute;
  width: 100%;
  text-align: center;
  background: #fff;
  bottom: -1px;
  padding-bottom: 4px;
}
.de-el-dropdown-menu-manu .text-area .text-area-btn .btn {
  font-weight: 400;
  text-align: center;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  height: 28px;
  padding: 0 15px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  outline: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  line-height: 1;
  -webkit-appearance: button;
  cursor: pointer;
  border-radius: 0;
  width: 70px;
  border: 1px solid rgba(33, 83, 212, 0.9);
}
.de-el-dropdown-menu-manu .text-area .text-area-btn .right-add {
  background: #2153d4;
  color: #fff;
}
.clearfix:after {
  content: "020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}