.ds-table-drawer .table-value,
.ds-table-drawer .table-name {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.ds-table-drawer .table-name {
  color: var(--deTextSecondary, #646a73);
}
.ds-table-drawer .table-value {
  margin: 4px 0 24px 0;
  color: var(--deTextPrimary, #1f2329);
}
.ds-table {
  height: 100%;
  width: 100%;
}
.ds-table .mar3 {
  margin-left: -5px;
}
.ds-table .table-name-top {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--deTextPrimary, #1f2329);
}
.ds-table .table-container {
  height: calc(100% - 50px);
}
.ds-table .el-table__fixed-right::before {
  background: transparent;
}