
.template-item-main[data-v-58c046e6] {
  margin: 0 24px 0 0;
  float: left;
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 250px;
  height: 180px;
  background-color: var(--ContentBG,#ffffff);
  border: 1px solid #DEE0E3 ;
  border-radius: 4px;
  -webkit-box-flex: 0;
      -ms-flex: none;
          flex: none;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  -ms-flex-positive: 0;
      flex-grow: 0;
  cursor: pointer;
  overflow: hidden;
}
.template-item-main-active[data-v-58c046e6]{
  border: 2px solid #3370FF!important;
}
.template-item-img[data-v-58c046e6]{
  position: absolute;
  width: 248px;
  height: 138px;
  left: 0px;
  top: 0px;
}
.demonstration[data-v-58c046e6] {
  position: absolute;
  width: 166px;
  height: 20px;
  left: 8px;
  top: 148px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  display: block;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.template-item-main[data-v-58c046e6]:hover {
  border: solid 1px #3370FF;
}

