.demo_main[data-v-0fd34215] {
  height: 100%;
  padding: 10px 20px 10px 20px;
}
.demo_content[data-v-0fd34215] {
  padding-left: 10px;
  float: left;
  font-weight: 400;
  max-height: 80%;
  overflow: hidden;
}
.demo_bottom[data-v-0fd34215] {
  position: absolute;
  left: 30px;
  bottom: 10px;
  font-weight: 500;
  color: var(--MenuActiveBG, #409EFF);
}