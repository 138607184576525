.dataset-union[data-v-ae90d366] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
}
.dataset-union .union-editer-container[data-v-ae90d366] {
  min-height: 298px;
  width: 100%;
  background: #f5f6f7;
}
.dataset-union .preview-container[data-v-ae90d366] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  font-family: PingFang SC;
  font-size: 14px;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  flex: 1;
}
.dataset-union .preview-container .sql-title[data-v-ae90d366] {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  padding: 16px 24px;
  font-weight: 500;
  position: relative;
  color: var(--deTextPrimary, #1f2329);
  border-bottom: 1px solid rgba(31, 35, 41, 0.15);
}
.dataset-union .preview-container .sql-title .posi-right[data-v-ae90d366] {
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.dataset-union .preview-container .sql-title .result-num[data-v-ae90d366] {
  font-weight: 400;
  color: var(--deTextSecondary, #646a73);
  margin-left: 12px;
}
.dataset-union .preview-container .sql-title .drag[data-v-ae90d366] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 7px;
  width: 100px;
  border-radius: 3.5px;
  background: rgba(31, 35, 41, 0.1);
  cursor: row-resize;
}
.dataset-union .union-container[data-v-ae90d366] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 24px;
  width: 100%;
  overflow: auto;
  height: 100%;
}