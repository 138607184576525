.dataset-editer-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dataset-editer-form .w600 {
  width: 600px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.dataset-editer-form .w600 .el-radio:not(:last-child) {
  margin-right: 0;
  width: 156px;
}
.dataset-editer-form .w600 .simple-cron {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.dataset-editer-form .w600 .simple-cron .el-input-number,
.dataset-editer-form .w600 .simple-cron .el-select {
  width: 140px;
  margin-left: 8px;
}
.dataset-editer-form .w600 .simple-cron .el-input-number .el-input__inner,
.dataset-editer-form .w600 .simple-cron .el-select .el-input__inner {
  text-align: left;
}
.dataset-editer-form .w600 .simple-cron .el-select {
  margin-right: 8px;
}
.dataset-editer-form .w600 .execute-rate-cont {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px;
  width: 100%;
  background-color: var(--MainBG, #f5f6f7);
  border-radius: 4px;
}
.dataset-editer-form .w600 .execute-rate-cont .el-input__inner {
  background: var(--ContentBG, #ffffff) !important;
}
.dataset-editer-form .w600 .execute-rate-cont .el-date-editor {
  width: 100%;
}
.dataset-editer-form .w600 .add-scope-cont {
  height: 350px;
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  background-color: var(--MainBG, #f5f6f7);
}
.dataset-editer-form .w600 .add-scope-cont .param-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 9px;
}
.dataset-editer-form .w600 .add-scope-cont .param-title:nth-child(1) {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextPrimary, #1f2329);
}
.dataset-editer-form .w600 .add-scope-cont .codemirror-cont {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 560px;
  height: 200px;
  background: #ffffff;
  border: 1px solid #bbbfc4;
  border-radius: 4px;
  overflow: auto;
}
.de-foot-layout {
  position: absolute;
  width: calc(100% - 48px);
  height: 80px;
  bottom: 0;
  right: 24px;
  background-color: var(--MainBG, #fff);
  -webkit-box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
          box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 10;
}
.de-foot-layout .cont {
  width: 600px;
  text-align: right;
}