
*{
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin:0;
  padding:0;
}
ol,ul{
  list-style: none;
}
#slider{
  text-align: center;
}
.window{
  position:relative;
  width:240px;
  height:208px;
  margin:0 auto;
  overflow:hidden;
}
.container{
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  position:absolute;
}
.left, .right{
  position:absolute;
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
  width:20px;
  height:20px;
  background-color:rgba(0,0,0,.3);
  border-radius:50%;
  cursor:pointer;
}
.left{
  padding-left:5px;
  padding-top:2px;
}
.right{
  padding-right:5px;
  padding-top:2px;
}
img{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.dots{
  position:absolute;
  bottom:10px;
  left:50%;
  -webkit-transform:translateX(-50%);
          transform:translateX(-50%);
}
.dots li{
  display:inline-block;
  width:7px;
  height:7px;
  margin:0 3px;
  border:1px solid white;
  border-radius:50%;
  background-color:#333;
  cursor:pointer;
}
.dots .dotted{
  background-color:orange;
}
.direction{
  width: 100%;
}
