
.el-divider--horizontal[data-v-664f0a98] {
  margin: 12px 0
}
.table-name[data-v-664f0a98]{
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}
