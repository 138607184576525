
.ms-http-input[data-v-99348920] {
  width: 500px;
  margin-top: 5px;
}
.tip[data-v-99348920] {
  padding: 3px 5px;
  font-size: 16px;
  border-radius: 0;
  border-left: 4px solid #409EFF;
  margin: 5px 5px 10px 5px;
}
.el-select[data-v-99348920]  input {
  padding-right: 10px;
}
.el-select[data-v-99348920]  .el-input__suffix {
  right: 0;
}
.dialog-css[data-v-99348920]  .el-dialog__header {
  padding: 10px 20px 0px;
}
.dialog-css[data-v-99348920]  .el-dialog__body {
  padding: 10px 20px 10px;
}
.dialog-footer[data-v-99348920]  .el-dialog__footer {
  padding: 10px 10px 10px;
}

