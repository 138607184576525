.reset-pwd-icon {
  margin-top: 4px;
  color: rgb(255, 153, 0);
}
.reset-pwd {
  padding: 20px 24px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.reset-pwd .tips {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 8.67px;
  color: #1f2329;
}
.reset-pwd i {
  font-size: 14.6666660309px;
  color: #ff8800;
  line-height: 22px;
}
.reset-pwd .editer-form-title {
  margin: 4px 0 16px 24px;
}
.reset-pwd .editer-form-title .pwd,
.reset-pwd .editer-form-title .btn-text {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}
.reset-pwd .editer-form-title .pwd {
  margin-right: 8px;
  color: #1f2329;
}
.reset-pwd .editer-form-title .btn-text {
  border: none;
}
.reset-pwd .foot {
  text-align: right;
  width: 100%;
}
.reset-pwd .foot .btn {
  border-radius: 4px;
  padding: 4px 12px 4px 12px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.reset-pwd .foot .normal {
  color: #1f2329;
  border: 1px solid #bbbfc4;
  margin-left: 12px;
}
.list-columns-select {
  padding: 8px 11px !important;
  width: 238px;
}
.list-columns-select .title,
.list-columns-select .el-checkbox {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
  margin: 0;
  color: #8f959e;
}
.list-columns-select .el-checkbox {
  color: #1f2329;
  width: 100%;
}
.de-one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.de-table-tooltips {
  max-width: 200px;
}