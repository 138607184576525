.content-container {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  background-color: var(--ContentBG);
  overflow: auto;
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.content-container .content-container__header {
  line-height: 60px;
  font-size: 18px;
}
.content-container .content-container__toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}