
.ms-query[data-v-1a5094b5] {
  background: #409EFF;
  color: white;
  height: 18px;
  border-radius: 42%;
}
.ms-header[data-v-1a5094b5] {
  background: #409EFF;
  color: white;
  height: 18px;
  border-radius: 42%;
}
.request-tabs[data-v-1a5094b5] {
  margin: 20px;
  min-height: 200px;
}
.ms-el-link[data-v-1a5094b5] {
  float: right;
  margin-right: 45px;
}
