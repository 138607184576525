
.testcase-template[data-v-95ec6e90] {
  position: relative;
  display: inline-block;
  margin: 0;
  -webkit-box-shadow: 0 0 2px 0 rgba(31,31,31,0.15), 0 1px 2px 0 rgba(31,31,31,0.15);
          box-shadow: 0 0 2px 0 rgba(31,31,31,0.15), 0 1px 2px 0 rgba(31,31,31,0.15);
  border: solid 2px #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
}
.demonstration[data-v-95ec6e90] {
  display: block;
  font-size: 16px;
  text-align: left;
  margin-left: 12px;
  margin-top: 12px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  color: var(--TextPrimary, #1F2329);
}
.template-img[data-v-95ec6e90] {
  background-size: 100% 100%;
  margin: 0 auto;
  border: solid 2px #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.template-img[data-v-95ec6e90]:hover {
  border: solid 1px #4b8fdf;
  border-radius: 4px;
  color: deepskyblue;
  cursor: pointer;
}
.testcase-template[data-v-95ec6e90]:hover  .template-button{
  display: inline;
}
.template-button[data-v-95ec6e90] {
  display: none;
  text-align: center;
  position:absolute;
  bottom: 5px;
  left: 0px;
  width: 100%;
}
.bottom-area[data-v-95ec6e90]{
  height: 75px;
}
