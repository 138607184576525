.field-block-style[data-v-2630acf0] {
  height: 100%;
  width: 100%;
  font-family: PingFang SC;
}
.field-block-style .field-block-head[data-v-2630acf0] {
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: rgba(0, 0, 0, 0.05);
  border-top: 1px solid rgba(31, 35, 41, 0.15);
  border-bottom: 1px solid rgba(31, 35, 41, 0.15);
}
.field-block-style .field-block-body[data-v-2630acf0] {
  height: 281px;
  overflow-y: auto;
}
.field-block-style .field-origin-style[data-v-2630acf0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 12px;
  width: 140px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--deTextSecondary, #646a73);
}
.field-block-style .field-style[data-v-2630acf0] {
  width: 140px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  font-size: 14px;
  font-weight: 500;
  color: var(--deTextSecondary, #646a73);
}
.field-block-style .label-style[data-v-2630acf0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.field-block-style .field-item-style[data-v-2630acf0] {
  width: 100%;
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid rgba(31, 35, 41, 0.15);
}
.field-block-style .check-style[data-v-2630acf0] {
  width: 52px;
  text-align: center;
}
.field-block-style .check-style[data-v-2630acf0] .el-checkbox__label {
  display: none;
}
.field-block-style .origin-style[data-v-2630acf0] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.field-block-style .field-block-option[data-v-2630acf0] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-bottom: 16px;
}
.field-block-style .option-field[data-v-2630acf0] {
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextSecondary, #646a73);
}
.field-block-style .option-input[data-v-2630acf0] {
  width: 200px;
}
.field-block-style .value[data-v-2630acf0] {
  font-weight: 400;
  color: var(--deTextPrimary, #1f2329);
}