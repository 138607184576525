
.subject-template[data-v-402692f6] {
  width: 110px;
  height: 90px;
  position: relative;
  float: left;
  margin: 5px;
  display: inline-block;
}
.demonstration[data-v-402692f6] {
  display: block;
  text-align: center;
  margin: 10px auto;
  width: 150px;
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
.vertical-layout[data-v-402692f6] {
  overflow: hidden auto;
  position: absolute;
  inset: 0px 0px 30px;
  width: 108px; height: 58px;
  margin: 0 auto;
  -webkit-box-shadow: 0 0 2px 0 rgba(31,31,31,0.15), 0 1px 2px 0 rgba(31,31,31,0.15);
          box-shadow: 0 0 2px 0 rgba(31,31,31,0.15), 0 1px 2px 0 rgba(31,31,31,0.15);
  border: solid 1px #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 3px;
}
.vertical-layout[data-v-402692f6]:hover {
  border: solid 1px #4b8fdf;
  border-radius: 3px;
  color: deepskyblue;
  cursor: pointer;
}
.vertical-layout > i[data-v-402692f6]{
  float: right;
  color: gray;
  margin: 2px;
  position: relative;
}
.vertical-layout > i[data-v-402692f6]:hover {
  color: red;
}
.vertical-layout:hover > .el-icon-error[data-v-402692f6] {
  z-index: 10;
}
.vertical-layout:hover > .el-icon-edit[data-v-402692f6] {
  z-index: 10;
}

