.card_main[data-v-644de4f8] {
  padding: 5% 25px 30px 25px;
  min-height: 250px;
  height: 33vh;
  max-height: 300px;
}
.card_content[data-v-644de4f8] {
  border: lightgray solid 1px;
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
}