
.kv-description[data-v-2fd623fc] {
  font-size: 13px;
}
.kv-row[data-v-2fd623fc] {
  margin-top: 10px;
}
.kv-delete[data-v-2fd623fc] {
  width: 60px;
}
.kv-select[data-v-2fd623fc] {
  width: 50%;
}
.el-autocomplete[data-v-2fd623fc] {
  width: 100%;
}
.kv-checkbox[data-v-2fd623fc] {
  width: 20px;
  margin-right: 10px;
}
.advanced-item-value[data-v-2fd623fc]  .el-dialog__body {
  padding: 15px 25px;
}
.el-row[data-v-2fd623fc] {
  margin-bottom: 5px;
}
.kv-type[data-v-2fd623fc] {
  width: 70px;
}
.pointer[data-v-2fd623fc] {
  cursor: pointer;
  color: #1E90FF;
}
.kv-setting[data-v-2fd623fc] {
  width: 40px;
  padding: 0px !important;
}
