.de-fill-block[data-v-3ce0698e] {
  margin-left: 25px !important;
}
.custom-tree-container[data-v-3ce0698e] {
  margin-top: 10px;
}
.custom-tree-container .no-tdata[data-v-3ce0698e] {
  text-align: center;
  margin-top: 80px;
  font-family: PingFang SC;
  font-size: 14px;
  color: var(--deTextSecondary, #646a73);
  font-weight: 400;
}
.custom-tree-container .no-tdata .no-tdata-new[data-v-3ce0698e] {
  cursor: pointer;
  color: var(--primary, #3370ff);
}
.tree-list[data-v-3ce0698e]  .el-tree-node__expand-icon.is-leaf {
  display: none;
}
.custom-tree-node[data-v-3ce0698e] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.custom-tree-node-list[data-v-3ce0698e] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding: 0 8px;
}
.custom-position[data-v-3ce0698e] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
.form-item[data-v-3ce0698e] {
  margin-bottom: 0;
}
.title-css[data-v-3ce0698e] {
  height: 26px;
}
.title-text[data-v-3ce0698e] {
  line-height: 26px;
}
.scene-title[data-v-3ce0698e] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.scene-title-name[data-v-3ce0698e] {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.father .child[data-v-3ce0698e] {
  visibility: hidden;
}
.father:hover .child[data-v-3ce0698e] {
  visibility: visible;
}
.inner-dropdown-menu[data-v-3ce0698e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}