.video_main[data-v-4dc7ff01] {
  width: 240px;
  height: 192px;
  border: 1px solid #DEE0E3;
  float: left;
  border-radius: 4px;
}
.video_main[data-v-4dc7ff01]:hover {
  -webkit-box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
          box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
}
.video_top[data-v-4dc7ff01] {
  position: relative;
  width: 240px;
  height: 135px;
}
.video_top img[data-v-4dc7ff01] {
  width: 238px;
  height: 135px;
}
.video_content[data-v-4dc7ff01] {
  width: 240px;
  padding: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--TextPrimary, #1F2329);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.play-icon[data-v-4dc7ff01] {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 105px;
  top: 53px;
}