
.el-divider--horizontal[data-v-62a74af6] {
  margin: 12px 0;
}
.el-radio[data-v-62a74af6]{
  margin-right: 10px;
}
.el-radio[data-v-62a74af6]  .el-radio__label{
  font-size: 12px;
}
.dialog-css[data-v-62a74af6]  .el-dialog__header {
  padding: 20px 20px 0;
}
.dialog-css[data-v-62a74af6]  .el-dialog__body {
  padding: 10px 20px 20px;
}
.el-form-item[data-v-62a74af6] {
  margin-bottom: 10px;
}
.codemirror[data-v-62a74af6] {
  height: 100px;
  overflow-y: auto;
}
.codemirror[data-v-62a74af6]  .CodeMirror-scroll {
  height: 100px;
  overflow-y: auto;
}
.err-msg[data-v-62a74af6]{
  font-size: 12px;
  word-break:normal;
  width:auto;
  display:block;
  white-space:pre-wrap;
  word-wrap : break-word ;
  overflow: hidden ;
}
.blackTheme .dialog_cancel_button[data-v-62a74af6] {
  background-color: #171b22 !important;
  color: #2681ff !important;
}
.span[data-v-62a74af6]{
  font-size: 12px;
}
