.head[data-v-0ce992af] {
  text-align: center;
  color: white;
  padding: 10px;
  margin-top: 35px;
  background-size: 100% 100% !important;
  background-image: url(../img/banner.cdb63a76.png);
}
.hint_head[data-v-0ce992af] {
  line-height: 50px;
  font-weight: bold;
  font-size: 25px;
}
.hint_content[data-v-0ce992af] {
  line-height: 50px;
  font-size: 15px;
}
.card_container[data-v-0ce992af] {
  vertical-align: middle;
}