.top-install {
  position: absolute;
  top: 24px;
  right: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.top-install .el-input {
  margin-right: 12px;
}
.top-install .el-input__inner {
  background: var(--ContentBG, #ffffff) !important;
}
.plugin-cont {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: var(--MainBG, #f5f6f7);
  overflow-y: auto;
  -ms-flex-line-pack: start;
      align-content: flex-start;
}
.de-card-plugin {
  width: 270px;
  min-height: 188px;
  background: var(--ContentBG, #ffffff);
  border: 1px solid var(--deCardStrokeColor, #dee0e3);
  border-radius: 4px;
  margin: 0 24px 24px 0;
}
.de-card-plugin:hover {
  -webkit-box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
          box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
}
.de-card-plugin .card-method {
  border-top: 1px solid #dee0e3;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 9px 30px 10px 30px;
  width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.de-card-plugin .card-method .btn-plugin {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  /* identical to box height, or 157% */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  letter-spacing: -0.1px;
  /* Neutral/600 */
  color: #646a73;
}
.de-card-plugin .card-method .btn-plugin i {
  font-size: 13px;
  margin-right: 5.33px;
}
.de-card-plugin .card-method .btn-plugin.is-disable {
  cursor: not-allowed;
  color: #bbbfc4;
}
.de-card-plugin .card-method .btn-plugin.update:not(.is-disable):hover {
  color: var(--primaryHover, #26acff);
}
.de-card-plugin .card-method .btn-plugin.uninstall:not(.is-disable):hover {
  color: var(--deDangerHover, #26acff);
}
.de-card-plugin .btn-0 {
  display: none;
}
.de-card-plugin .card-info {
  width: 100%;
  height: 188px;
  padding: 12px;
  padding-bottom: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.de-card-plugin .card-info .info-top {
  margin-bottom: 12px;
  overflow: hidden;
}
.de-card-plugin .card-info .info-top .el-image {
  float: left;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.de-card-plugin .card-info .info-top .el-image .image-slot {
  padding: 4px;
}
.de-card-plugin .card-info .info-top img {
  width: 100%;
  height: 100%;
}
.de-card-plugin .card-info .info-top .title {
  width: 190px;
  height: 22px;
  float: left;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin: -2px 0 0 6px;
}
.de-card-plugin .card-info .info-top .tips {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  float: left;
  height: 20px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  margin: 2px 0 0 6px;
}
.de-card-plugin .card-info .list {
  padding-bottom: 8px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin: 0;
  color: #646a73;
}
.de-card-plugin .card-info .info-left {
  display: inline-block;
}
.de-card-plugin .card-info .info-left .name {
  color: #646a73;
}
.de-card-plugin .card-info .info-right {
  display: inline-block;
  margin-left: 12px;
}
.de-card-plugin .card-info .info-right .value {
  color: var(--TextPrimary, #1F2329);
}