
.shape-item[data-v-095380ba] {
  padding: 6px;
  border: none;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}
.form-item-slider[data-v-095380ba]  .el-form-item__label {
  font-size: 12px;
  line-height: 38px;
}
.form-item[data-v-095380ba]  .el-form-item__label {
  font-size: 12px;
}
.el-select-dropdown__item[data-v-095380ba] {
  padding: 0 20px;
}
span[data-v-095380ba] {
  font-size: 12px
}
.el-form-item[data-v-095380ba] {
  margin-bottom: 6px;
}
.color-picker-style[data-v-095380ba] {
  cursor: pointer;
  z-index: 1003;
}
.color-label[data-v-095380ba] {
  display: inline-block;
  width: 60px;
}
.color-type[data-v-095380ba]  .el-radio__input {
  display: none;
}
.el-radio[data-v-095380ba] {
  margin: 0 2px 0 0 !important;
  border: 1px solid transparent;
}
.el-radio[data-v-095380ba]  .el-radio__label {
  padding-left: 0;
}
.el-radio.is-checked[data-v-095380ba] {
  border: 1px solid #0a7be0;
}
.span-label[data-v-095380ba] {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.custom-color-style[data-v-095380ba] {
  height: 300px;
  overflow-y: auto;
  padding: 4px;
  border: 1px solid #e6e6e6;
}
