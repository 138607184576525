.table-count[data-v-23dd0b4d] {
  color: var(--deTextSecondary, #606266);
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin: 4px 0;
}
.table-count .title-text[data-v-23dd0b4d] {
  margin: 0 5px 0 0;
}