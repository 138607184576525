.de-user-drawer .el-table::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-top: 1px solid rgba(31, 35, 41, 0.15);
}
.update-drawer-task .el-drawer__body {
  padding: 24px;
  padding-bottom: 80px;
  position: unset;
}
.update-drawer-task .simple-cron {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}
.update-drawer-task .simple-cron .el-input-number,
.update-drawer-task .simple-cron .el-select {
  width: 140px;
  margin-left: 8px;
}
.update-drawer-task .simple-cron .el-input-number .el-input__inner,
.update-drawer-task .simple-cron .el-select .el-input__inner {
  text-align: left;
}
.update-drawer-task .simple-cron .el-select {
  margin-right: 8px;
}
.update-drawer-task .execute-rate-cont {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 20px;
  width: 100%;
  background-color: var(--MainBG, #f5f6f7);
  border-radius: 4px;
}
.update-drawer-task .execute-rate-cont .el-input__inner {
  background: var(--ContentBG, #ffffff) !important;
}
.update-drawer-task .execute-rate-cont .el-date-editor {
  width: 100%;
}
.update-drawer-task .add-scope-cont {
  height: 350px;
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  background-color: var(--MainBG, #f5f6f7);
}
.update-drawer-task .add-scope-cont .param-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-bottom: 9px;
}
.update-drawer-task .add-scope-cont .param-title:nth-child(1) {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextPrimary, #1f2329);
}
.update-drawer-task .add-scope-cont .codemirror-cont {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 200px;
  background: #ffffff;
  border: 1px solid #bbbfc4;
  border-radius: 4px;
  overflow: auto;
}