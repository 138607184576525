.api-table-delete {
  padding: 20px 24px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.api-table-delete .small {
  height: 28px;
  min-width: 48px !important;
}
.api-table-delete .tips {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 8.67px;
  color: var(--deTextPrimary, #1f2329);
}
.api-table-delete i {
  font-size: 14.6666660309px;
  color: var(--deWarning, #ff8800);
  line-height: 22px;
}
.api-table-delete .foot {
  text-align: right;
  width: 100%;
  margin-top: 16px;
}
.de-expand {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #3370ff;
  cursor: pointer;
  margin: 12px 0 16px 0;
  display: inline-block;
}
.de-mar0 {
  margin-top: 0;
}
.api-datasource-drawer .el-drawer__header {
  padding: 16px 24px;
  margin: 0;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1f2329;
  position: relative;
  border-bottom: 1px solid rgba(187, 191, 196, 0.5);
}
.api-datasource-drawer .el-drawer__header .el-drawer__close-btn {
  position: absolute;
  right: 24px;
  top: 16px;
  padding: 4px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.api-datasource-drawer .el-drawer__header .el-drawer__close-btn:hover {
  background: #e9e9ea;
}
.api-datasource-drawer .el-drawer__body {
  padding: 0 24px 81px 24px;
}
.api-datasource-drawer .row-rules {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding-left: 10px;
  margin: 24px 0 16px 0;
}
.api-datasource-drawer .row-rules::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 14px;
  width: 2px;
  background: #3370ff;
}
.api-datasource-drawer .input-with-select .el-input-group__prepend {
  background-color: #fff;
  border-color: #bbbfc4;
}
.api-datasource-drawer .input-with-select .el-input-group__prepend .el-select {
  width: 84px !important;
  color: #1f2329;
}
.api-datasource-drawer ::v-deep .el-form-item__label {
  width: 100% !important;
  text-align: left;
}
.api-datasource-drawer .el-steps {
  margin-top: 23px !important;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.api-datasource-drawer .el-steps .el-step__line {
  line-height: 1.5;
  text-align: center;
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
  position: absolute;
  background-color: rgba(187, 191, 196, 0.5);
  height: 1px;
  top: 13px;
  left: calc(50% + 22px) !important;
  width: 100px;
  margin-right: 0px;
}
.api-datasource-drawer .el-steps .el-step__line i {
  border-top: none;
}
.api-datasource-drawer .el-steps .el-step__icon.is-text {
  border: none;
  font-family: PingFang SC;
  font-weight: 400;
  color: #fff;
  width: 28px;
  height: 28px;
  background: #3370ff;
}
.api-datasource-drawer .el-steps .el-step__icon.is-icon i {
  font-size: 27px;
}
.api-datasource-drawer .el-steps .el-step__title {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #1f2329;
  margin-top: 4px;
}
.api-datasource-drawer .el-steps .is-process .el-step__icon.is-text {
  background: none;
  color: #8f959e;
  border: 1px solid #8f959e;
}
.api-datasource-drawer .el-steps .is-process.el-step__title {
  font-weight: 400;
  color: #8f959e;
}
.api-datasource-drawer .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}
.api-datasource-drawer .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label::after {
  content: "*";
  color: #f54a45;
  margin-left: 2px;
}
.api-datasource-drawer ::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
.api-datasource-drawer .el-form-item {
  margin-bottom: 28px;
}
.api-datasource-drawer .el-form-item__label {
  padding-bottom: 8px;
}
.api-datasource-drawer .mr40 {
  margin-top: 40px;
}
.api-datasource-drawer .btn {
  border-radius: 4px;
  padding: 5px 26px 5px 26px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  border: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.api-datasource-drawer .normal {
  color: #1f2329;
  border: 1px solid #bbbfc4;
  margin-left: 12px;
}
.api-datasource-drawer .table-container {
  padding: 20px;
  border: 1px solid #dee0e3;
}
.api-datasource-drawer .table-container .el-table__header-wrapper {
  background-color: #f5f6f7;
  border-top: 1px solid #dee0e3;
}
.api-datasource-drawer .table-container .el-table__fixed-header-wrapper th {
  background-color: var(--TableBG, #f5f6f7) !important;
}
.api-datasource-drawer .foot {
  width: 100%;
  z-index: 5;
  height: 80px;
  padding: 24px;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
  background: #ffffff;
  -webkit-box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
          box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.05);
}
.api-datasource-drawer .el-checkbox__label {
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ds-configuration-input .el-input__inner {
  color: var(--deTextPrimary, #1F2329);
}
.checkbox-table .el-checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.checkbox-table .el-checkbox .el-checkbox__input {
  margin-top: 4px;
}
.checkbox-table .cell.el-tooltip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}