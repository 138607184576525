.de-card-model {
  position: relative;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #ffffff;
  border: 1px solid var(--deCardStrokeColor, #dee0e3);
  border-radius: 4px;
  margin: 0 24px 25px 0;
}
.de-card-model .card-img-model {
  border-bottom: 1px solid var(--deCardStrokeColor, #dee0e3);
  height: 144px;
  width: 100%;
  overflow: hidden;
}
.de-card-model .card-img-model img {
  width: 100%;
  height: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.de-card-model .card-info {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 8px 12px 9px 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.de-card-model .card-info .el-icon-more {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  color: #646a73;
  cursor: pointer;
}
.de-card-model .card-info .el-icon-more:hover {
  background: rgba(31, 35, 41, 0.1);
  border-radius: 4px;
}
.de-card-model .card-info .el-icon-more:active {
  background: rgba(31, 35, 41, 0.2);
  border-radius: 4px;
}
.de-card-model:hover .card-info-apply {
  height: 92px;
}
.de-card-model:hover .market-button-area {
  display: block;
}
.de-card-model .market-button-area {
  text-align: center;
  margin-top: 4px;
  display: none;
}
.de-card-model .card-info-apply {
  background: #ffffff;
  width: 100%;
  height: 48px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  -webkit-transition: height 0.3s ease-out;
  transition: height 0.3s ease-out;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 12px 12px 12px 12px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid var(--deCardStrokeColor, #dee0e3);
  overflow-y: hidden;
}
.de-model-text {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1f2329;
  display: inline-block;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
}
.de-model-text-market {
  font-family: "PingFang SC";
  font-style: normal;
  color: #1f2329;
  display: inline-block;
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.de-card-model:hover {
  -webkit-box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
          box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.08);
}
.de-card-dropdown {
  margin-top: 0 !important;
}
.de-card-dropdown .popper__arrow {
  display: none !important;
}