.de-task-record[data-v-3e3c2884] {
  height: 100%;
  background-color: var(--MainBG, #f5f6f7);
}
.de-task-record .tabs-container[data-v-3e3c2884] {
  height: calc(100% - 48px);
  background: var(--ContentBG, #ffffff);
  overflow-x: auto;
}
.de-task-record[data-v-3e3c2884] .el-tabs__header {
  margin: 0 0 12px;
}
.de-task-record[data-v-3e3c2884] .el-tabs__item {
  height: 24px;
  line-height: 24px;
  margin-bottom: 9px;
  padding: 0 12px;
  font-size: 16px;
}