.table-container[data-v-97faf44c] {
  height: calc(100% - 50px);
}
.table-container-filter[data-v-97faf44c] {
  height: calc(100% - 110px);
}
.link-span[data-v-97faf44c] {
  color: #3370FF;
  cursor: pointer;
}
.link-span[data-v-97faf44c]:hover {
  text-decoration: underline;
}