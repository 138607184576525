
.ms-aside-container[data-v-1e2dbb51] {
  height: calc(100vh - 56px);
  padding: 0 0;
  min-width: 260px;
  max-width: 460px;
}
.ms-main-container[data-v-1e2dbb51] {
  height: calc(100vh - 56px);
  padding: 10px 15px 0 15px;
  background: #fff;
}
