.demo_main[data-v-b5ac39b2] {
  height: 100%;
  padding: 10px 20px 10px 20px;
}
.demo_content[data-v-b5ac39b2] {
  padding-left: 10px;
  height: 30%;
}
.demo_git[data-v-b5ac39b2] {
  margin-left: 10px;
  color: var(--MenuActiveBG, #409EFF);
}
.demo_bottom[data-v-b5ac39b2] {
  position: absolute;
  left: 30px;
  bottom: 10px;
  font-weight: 500;
  color: var(--MenuActiveBG, #409EFF);
}