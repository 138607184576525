.custom-tree-container[data-v-1f9608b1] {
  margin-top: 16px;
}
.custom-tree-container .no-tdata[data-v-1f9608b1] {
  text-align: center;
  margin-top: 80px;
  font-family: PingFang SC;
  font-size: 14px;
  color: var(--deTextSecondary, #646a73);
  font-weight: 400;
}
.custom-tree-container .no-tdata .no-tdata-new[data-v-1f9608b1] {
  cursor: pointer;
  color: var(--primary, #3370ff);
}
.custom-tree-node[data-v-1f9608b1] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  width: 100%;
}
.custom-tree-node-list[data-v-1f9608b1] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding: 0 8px;
  width: calc(100% - 40px);
}
.custom-tree-node-list .child[data-v-1f9608b1] {
  /*display: none;*/
  visibility: hidden;
}
.custom-tree-node-list:hover .child[data-v-1f9608b1] {
  /*display: inline;*/
  visibility: visible;
}
.tree-list[data-v-1f9608b1]  .el-tree-node__expand-icon.is-leaf {
  display: none;
}
.tree-style[data-v-1f9608b1] {
  padding: 16px 24px;
  height: 100%;
  overflow-y: auto;
}
.tree-style .title-text[data-v-1f9608b1] {
  line-height: 26px;
  color: #1f2329;
  margin-bottom: 16px;
}
.tree-style .title-operate[data-v-1f9608b1] {
  text-align: right;
}
.tree-style .title-operate i[data-v-1f9608b1] {
  margin-left: 22.91px;
}