
.table-name-css[data-v-78507be0] {
  margin: 4px 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.union-relation-css[data-v-78507be0] {
  display: block;
  width: 100%;
  padding: 4px 10px;
}
.dialog-css[data-v-78507be0]  .el-dialog__title {
  font-size: 14px;
}
.dialog-css[data-v-78507be0]  .el-dialog__header {
  padding: 20px 20px 0;
}
.dialog-css[data-v-78507be0]  .el-dialog__body {
  padding: 10px 20px 20px;
}
