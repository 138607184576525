
.kv-description[data-v-97653250] {
  font-size: 13px;
}
.kv-row[data-v-97653250] {
  margin-top: 10px;
}
.kv-checkbox[data-v-97653250] {
  width: 20px;
  margin-right: 10px;
}
.kv-delete[data-v-97653250] {
  width: 60px;
}
.el-autocomplete[data-v-97653250] {
  width: 100%;
}
i[data-v-97653250]:hover {
  color: #783887;
}
