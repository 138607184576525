.de-set-count {
  padding: 20px 24px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextPrimary, #1f2329);
  text-align: left;
}
.de-set-count .el-input {
  margin: 8px 0 20px 0;
}
.de-set-count .de-button {
  min-width: 48px;
  height: 28px;
}