.de-tab[data-v-4e2545ee] {
  border: 1px solid #E6E6E6;
  min-height: 200px !important;
  max-height: 300px !important;
  overflow: auto;
}
.de-icon[data-v-4e2545ee] {
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 99;
}
.el-input-group__append[data-v-4e2545ee] {
  background-color: #ffffff;
}
.el-input__inner[data-v-4e2545ee] {
  border-right: none;
}
.auth-root-class[data-v-4e2545ee] {
  margin: 15px 0px 5px;
  text-align: right;
}
.de-main-container-auth[data-v-4e2545ee] {
  border: 1px solid #E6E6E6;
  height: auto;
}
.blackTheme .de-main-container-auth[data-v-4e2545ee] {
  border-color: #495865;
}