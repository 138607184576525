.field-style[data-v-21942e6a] {
  height: 430px;
  border: 1px solid var(--deCardStrokeColor, #dee0e3);
  border-radius: 4px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 36px;
}
.fields[data-v-21942e6a] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 16px;
  width: 50%;
}
.fields > p[data-v-21942e6a] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  color: var(--deTextPrimary, #1f2329);
}
.fields[data-v-21942e6a]:nth-child(1) {
  border-right: 1px solid var(--deCardStrokeColor, #dee0e3);
}