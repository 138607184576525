
.err-msg[data-v-53e98d7b] {
  font-size: 12px;
  word-break: normal;
  width: auto;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}
.blackTheme .dialog_cancel_button[data-v-53e98d7b] {
  background-color: #171b22 !important;
  color: #2681ff !important;
}
.span[data-v-53e98d7b] {
  font-size: 12px;
}
