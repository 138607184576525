[data-v-a2c429f0] a:hover {
  color: blue;
}
.demo_main[data-v-a2c429f0] {
  height: 100%;
}
.demo_title[data-v-a2c429f0] {
  float: left;
  height: 100%;
}
.demo_content[data-v-a2c429f0] {
  margin: auto;
  padding-left: 8px;
  height: 100%;
  overflow: hidden;
}
.demo_content .head[data-v-a2c429f0] {
  padding-top: 24px;
  color: var(--TopTextColor, #000000);
}
.demo_content .content[data-v-a2c429f0] {
  height: 50%;
  padding-top: 15px;
  padding-right: 3px;
  color: var(--TextPrimary, #6D6D6D);
  font-size: 12px;
  overflow-y: auto;
}
.demo_content .bottom[data-v-a2c429f0] {
  height: 25%;
}
.demo_content .bottom .span-box[data-v-a2c429f0] {
  color: var(--TextPrimary, #6D6D6D);
  font-size: 12px;
  position: absolute;
  bottom: 16px;
}