.card_main[data-v-69f566f6] {
  float: left;
  width: 384px;
  height: 190px;
  padding: 24px;
  opacity: 0.9;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}
.card_main[data-v-69f566f6]:hover {
  -webkit-box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.5);
          box-shadow: 0px 6px 24px rgba(31, 35, 41, 0.5);
}
.card_head[data-v-69f566f6] {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  width: 230px;
}
.card_content[data-v-69f566f6] {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 230px;
  height: 66px;
  margin-top: 12px;
  color: #646A73;
  overflow-y: auto;
}
.card_bottom[data-v-69f566f6] {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 14px;
  line-height: 22px;
  color: #3370FF;
}
.triangle[data-v-69f566f6] {
  width: 0;
  height: 0;
  border-bottom: 30px solid var(--MenuActiveBG, #409EFF);
  border-right: 30px solid transparent;
  -webkit-transform: rotate(180deg);
  transform: rotate(0deg);
}
.img-position[data-v-69f566f6] {
  width: 160px;
  height: 190px;
  position: absolute;
  right: 0px;
  top: 0px;
}