
.el-divider--horizontal[data-v-7bc7f3fa] {
  margin: 12px 0;
}
.search-input[data-v-7bc7f3fa] {
  padding: 12px 0;
}
.tree-list[data-v-7bc7f3fa]  .el-tree-node__expand-icon.is-leaf {
  display: none;
}
.custom-tree-node[data-v-7bc7f3fa] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.custom-tree-node-list[data-v-7bc7f3fa] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
  padding: 0 8px;
}
.custom-position[data-v-7bc7f3fa] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 14px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
}
.form-item[data-v-7bc7f3fa] {
  margin-bottom: 0;
}
.title-css[data-v-7bc7f3fa] {
  height: 26px;
}
.title-text[data-v-7bc7f3fa] {
  line-height: 26px;
}
.scene-title[data-v-7bc7f3fa] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.scene-title-name[data-v-7bc7f3fa] {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tree-style[data-v-7bc7f3fa] {
  padding: 10px;
  height: 100%;
  overflow-y: auto;
}
