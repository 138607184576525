
.codemirror[data-v-397f46e7] {
  height: 100px;
  overflow-y: auto;
}
.codemirror[data-v-397f46e7]  .CodeMirror-scroll {
  height: 100px;
  overflow-y: auto;
}
.err-msg[data-v-397f46e7] {
  font-size: 12px;
  word-break: normal;
  width: auto;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}
