.dataset-excel[data-v-24aa5e35] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}
.dataset-excel .arrow-right[data-v-24aa5e35] {
  position: absolute;
  z-index: 2;
  top: 15px;
  cursor: pointer;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  height: 24px;
  width: 20px;
  -webkit-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--deCardStrokeColor, #dee0e3);
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.dataset-excel .table-list[data-v-24aa5e35] {
  height: 100%;
  width: 240px;
  padding: 16px 12px;
  font-family: PingFang SC;
  border-right: 1px solid rgba(31, 35, 41, 0.15);
}
.dataset-excel .table-list p[data-v-24aa5e35] {
  margin: 0;
}
.dataset-excel .table-list .select-ds[data-v-24aa5e35] {
  font-size: 14px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: var(--deTextPrimary, #1f2329);
}
.dataset-excel .table-list .select-ds i[data-v-24aa5e35] {
  font-size: 14px;
  color: var(--deTextPlaceholder, #8f959e);
}
.dataset-excel .table-list .search[data-v-24aa5e35] {
  margin: 12px 0;
}
.dataset-excel .table-list .table-checkbox-list[data-v-24aa5e35] {
  height: calc(100% - 100px);
  overflow-y: auto;
}
.dataset-excel .table-list .table-checkbox-list .custom-tree-node[data-v-24aa5e35] {
  position: relative;
  width: 80%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.dataset-excel .table-list .table-checkbox-list .custom-tree-node .label[data-v-24aa5e35] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 85%;
}
.dataset-excel .table-list .table-checkbox-list .error-name-exist[data-v-24aa5e35] {
  position: absolute;
  top: 0;
  right: 0;
}
.dataset-excel .table-list .table-checkbox-list .item[data-v-24aa5e35] {
  height: 40px;
  width: 215px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 12px;
}
.dataset-excel .table-list .table-checkbox-list .item[data-v-24aa5e35]:hover {
  background: rgba(31, 35, 41, 0.1);
}
.dataset-excel .table-list .table-checkbox-list .item.active[data-v-24aa5e35] {
  background-color: var(--deWhiteHover, #3370ff);
  color: var(--primary, #3370ff);
}
.dataset-excel .table-list .table-checkbox-list .item .el-checkbox__label[data-v-24aa5e35] {
  overflow: hidden;
}
.dataset-excel .table-detail[data-v-24aa5e35] {
  font-family: PingFang SC;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-x: auto;
}
.dataset-excel .table-detail .dataset[data-v-24aa5e35] {
  padding: 21px 24px;
  width: 100%;
  border-bottom: 1px solid rgba(31, 35, 41, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.dataset-excel .table-detail .dataset .name[data-v-24aa5e35] {
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextPrimary, #1f2329);
}
.dataset-excel .table-detail .dataset .el-input[data-v-24aa5e35] {
  width: 420px;
  margin-left: 12px;
}
.dataset-excel .table-detail .data[data-v-24aa5e35] {
  padding: 16px 24px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: calc(100% - 80px);
  overflow-y: auto;
}
.dataset-excel .table-detail .data .result-num[data-v-24aa5e35] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextSecondary, #646a73);
  margin-bottom: 16px;
}
.dataset-excel .table-detail .data .type-switch[data-v-24aa5e35] {
  padding: 2px 1.5px;
  display: inline-block;
  cursor: pointer;
}
.dataset-excel .table-detail .data .type-switch i[data-v-24aa5e35] {
  margin-left: 4px;
  font-size: 12px;
}
.dataset-excel .table-detail .data .type-switch[data-v-24aa5e35]:hover {
  background: rgba(31, 35, 41, 0.1);
  border-radius: 4px;
}