.de-ds-form-app[data-v-2d9ac252] {
  width: 100%;
  height: 100%;
}
.de-ds-form-app .de-ds-cont[data-v-2d9ac252] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 12px 24px 24px 24px;
}
.de-ds-form-app .de-ds-cont .de-ds-inner[data-v-2d9ac252] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.de-ds-form-app .de-ds-cont .w600[data-v-2d9ac252] {
  width: 600px;
  height: 100%;
}
.de-ds-form-app .de-ds-bottom[data-v-2d9ac252] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: right;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 56px;
  padding: 12px 24px;
  -webkit-box-shadow: 2px 2px 4px rgba(31, 35, 41, 0.08);
          box-shadow: 2px 2px 4px rgba(31, 35, 41, 0.08);
}
.de-ds-form-app .de-ds-bottom .name[data-v-2d9ac252] {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--deTextPrimary, #1f2329);
}
.de-ds-form-app .de-ds-bottom i[data-v-2d9ac252] {
  cursor: pointer;
}
.de-ds-form[data-v-2d9ac252] {
  width: 100vw;
  height: 100vh;
}
.de-ds-form .de-ds-top[data-v-2d9ac252] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 56px;
  padding: 12px 24px;
  -webkit-box-shadow: 0px 2px 4px rgba(31, 35, 41, 0.08);
          box-shadow: 0px 2px 4px rgba(31, 35, 41, 0.08);
}
.de-ds-form .de-ds-top .name[data-v-2d9ac252] {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--deTextPrimary, #1f2329);
}
.de-ds-form .de-ds-top i[data-v-2d9ac252] {
  cursor: pointer;
}