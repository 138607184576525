.de-template-list[data-v-5f92c6fa] {
  height: 100%;
  position: relative;
}
.de-template-list ul[data-v-5f92c6fa] {
  margin: 16px 0 20px 0;
  padding: 0;
  overflow-y: auto;
  max-height: calc(100% - 90px);
}
.de-template-list li[data-v-5f92c6fa] {
  list-style: none;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 40px;
  padding: 0 30px 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  color: var(--deTextPrimary, #1f2329);
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.de-template-list li .folder[data-v-5f92c6fa] {
  color: #8f959e;
  margin-right: 9px;
}
.de-template-list li .more[data-v-5f92c6fa] {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: none;
}
.de-template-list li .more .el-icon-more[data-v-5f92c6fa] {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  color: #646a73;
  cursor: pointer;
}
.de-template-list li .more .el-icon-more[data-v-5f92c6fa]:hover {
  background: rgba(31, 35, 41, 0.1);
  border-radius: 4px;
}
.de-template-list li .more .el-icon-more[data-v-5f92c6fa]:active {
  background: rgba(31, 35, 41, 0.2);
  border-radius: 4px;
}
.de-template-list li[data-v-5f92c6fa]:hover {
  background: rgba(31, 35, 41, 0.1);
}
.de-template-list li:hover .more[data-v-5f92c6fa] {
  display: block;
}
.de-template-list li.select[data-v-5f92c6fa] {
  background: var(--deWhiteHover, #ecf5ff) !important;
  color: var(--TextActive, #3370ff) !important;
}
.de-template-list .de-btn-fix[data-v-5f92c6fa] {
  position: absolute;
  bottom: 0;
  left: 0;
}
.de-template-dropdown[data-v-5f92c6fa] {
  margin-top: 0 !important;
}
.de-template-dropdown .popper__arrow[data-v-5f92c6fa] {
  display: none !important;
}