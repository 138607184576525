.leftPanel-background[data-v-3ee1d4d6] {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
}
.leftPanel[data-v-3ee1d4d6] {
  width: 100%;
  max-width: 260px;
  height: calc(100vh - 91px);
  position: fixed;
  top: 91px;
  left: 60px;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  -webkit-transform: translate(100%);
          transform: translate(100%);
  background: #fff;
  z-index: 1003;
}
.show[data-v-3ee1d4d6] {
  -webkit-transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.show .leftPanel-background[data-v-3ee1d4d6] {
  z-index: 1002;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.show .leftPanel[data-v-3ee1d4d6] {
  -webkit-transform: translate(0);
          transform: translate(0);
}