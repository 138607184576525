.de-expand[data-v-9952cf48] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #3370ff;
  cursor: pointer;
  margin: 12px 0 16px 0;
  display: inline-block;
}
.el-input-number[data-v-9952cf48] {
  width: 100%;
}
.el-input-number[data-v-9952cf48]  .el-input__inner {
  text-align: left;
}
.el-input-number[data-v-9952cf48] .el-input-number__decrease,
.el-input-number[data-v-9952cf48] .el-input-number__increase {
  background: transparent;
}
.el-input-number.is-disabled .el-input-number__decrease[data-v-9952cf48] {
  border-right-color: #e4e7ed;
}
.el-input-number.is-disabled .el-input-number__increase[data-v-9952cf48] {
  border-bottom-color: #e4e7ed;
}