.de-template[data-v-18b45d92] {
  height: 100%;
  background-color: var(--MainBG, #f5f6f7);
}
.de-template .tabs-container[data-v-18b45d92] {
  height: 100%;
  background: var(--ContentBG, #ffffff);
  overflow-x: auto;
}
.de-template .flex-tabs[data-v-18b45d92] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #f5f6f7;
}
.de-template .de-tabs-left[data-v-18b45d92] {
  background: #fff;
  width: 269px;
  border-right: 1px solid rgba(31, 35, 41, 0.15);
  padding: 24px;
}
.de-template .de-tabs-right[data-v-18b45d92] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: #fff;
  padding: 24px 0 24px 24px;
  overflow: hidden;
}
.de-template .de-tabs-right .template-box[data-v-18b45d92] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-flex-line-pack: start;
      align-content: flex-start;
  height: calc(100% - 10px);
  width: 100%;
  padding-bottom: 24px;
}
.de-template .de-tabs-right .active-template[data-v-18b45d92] {
  margin: 4px 0 20px 0;
  padding-right: 24px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: var(--deTextPrimary, #1f2329);
}
[data-v-18b45d92] .container-wrapper {
  padding: 0px !important;
}