
.ds-node[data-v-c55e1358] {
  width: 160px;
  height: 26px;
  line-height: 26px;
  border: #dcdfe6 solid 1px;
  background: #fff;
  min-width: 160px;
  color: var(--TextPrimary, #606266);
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 6px;
}
.node-name[data-v-c55e1358] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  padding: 0 2px;
}
.ds-node .node-menu[data-v-c55e1358] {
  visibility: hidden;
}
.ds-node:hover .node-menu[data-v-c55e1358] {
  visibility: visible;
}
.ds-node[data-v-c55e1358]:hover {
  cursor: pointer;
  border: var(--Main, #2681ff) solid 1px;
}
.dialog-css[data-v-c55e1358]  .el-dialog__body {
  padding: 0 20px;
}
