.dataset-task-table {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 628px;
  border: 1px solid #dee0e3;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 628px;
}
.dataset-task-table .el-tree-node__content {
  height: 40px;
  border-radius: 4px;
}
.dataset-task-table .el-tree-node__content:hover {
  background-color: var(--deWhiteHover, #3370ff) !important;
}
.dataset-task-table .el-tree-node__content:hover .custom-tree-node {
  color: var(--primary, #3370ff);
}
.dataset-task-table .dataset-tree {
  width: 253px;
  border-right: 1px solid #dee0e3;
  padding: 16px;
}
.dataset-task-table .dataset-tree .tree {
  padding-top: 16px;
  overflow-y: auto;
  height: calc(100% - 30px);
}
.dataset-task-table .dataset-tree-table {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 16px;
  overflow-y: auto;
}
.dataset-task-table .dataset-tree-table .table-name {
  margin: 0;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--deTextPrimary, #000000);
}
.dataset-task-table .dataset-tree-table .table-name span {
  font-size: 14px;
  font-weight: 400;
  color: #646a73;
}