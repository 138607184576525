.flex-table[data-v-2ce980cb] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.flex-table[data-v-2ce980cb] .el-table-column--selection .cell {
  padding: 0 14px;
}
.flex-table .el-table[data-v-2ce980cb]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border-top: 1px solid rgba(31, 35, 41, 0.15);
}
.flex-table[data-v-2ce980cb]  .el-table__fixed-body-wrapper tr {
  background-color: var(--TableBG, #ffffff) !important;
}
.flex-table[data-v-2ce980cb]  .el-table__fixed-right::before {
  display: none;
}
.flex-table .pagination-cont[data-v-2ce980cb] {
  text-align: right;
  margin-top: 10px;
}
.flex-table .pagination-cont[data-v-2ce980cb]  .el-pager li {
  background-color: #fff;
  border: 1px solid #bbbfc4;
  border-radius: 4px;
  color: #1f2329;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 26px;
  font-family: SF Pro Text;
  font-size: 14px;
  font-weight: 400;
}
.flex-table .pagination-cont[data-v-2ce980cb]  .btn-prev,
.flex-table .pagination-cont[data-v-2ce980cb]  .btn-next {
  background: #fff;
  background-color: #fff;
  border: 1px solid #bbbfc4;
  border-radius: 4px;
  color: #bbbfc4;
}
.flex-table .pagination-cont[data-v-2ce980cb]  .el-pagination__total {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #1f2329;
  line-height: 28px;
}
.flex-table .pagination-cont[data-v-2ce980cb]  .number.active,
.flex-table .pagination-cont[data-v-2ce980cb]  .el-input__inner:hover {
  border-color: #3370ff;
  color: #3370ff !important;
  background-color: #fff !important;
}
.flex-table .pagination-cont[data-v-2ce980cb]  .el-icon-more {
  border: none !important;
}