.sys-setting[data-v-0cffac79] {
  height: 100%;
  background-color: var(--MainBG, #f5f6f7);
}
.sys-setting .tabs-container[data-v-0cffac79] {
  height: calc(100% - 48px);
  background: var(--ContentBG, #ffffff);
  overflow-x: auto;
}
.sys-setting .tabs-container .min-w600[data-v-0cffac79] {
  min-width: 600px;
  height: 100%;
}
.sys-setting .tabs-container .min-w600[data-v-0cffac79] > :nth-child(1) {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-bottom: 20px;
}
.sys-setting .is-center[data-v-0cffac79] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 20px 20px 20px;
}
.sys-setting .pad-center[data-v-0cffac79] {
  padding: 24px;
}