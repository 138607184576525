.white-nowrap[data-v-555edaca] {
  white-space: nowrap;
}
.filed[data-v-555edaca] {
  height: 41.4px;
  padding: 1px 3px 1px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 20px;
  min-width: 200px;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  position: relative;
  white-space: nowrap;
}
.filed[data-v-555edaca] .el-input--mini {
  height: 26px;
}
.filed .filed-title[data-v-555edaca] {
  word-wrap: break-word;
  line-height: 28px;
  color: #7e7e7e;
  font-size: 12px;
  white-space: nowrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-right: 5px;
  display: inline-block;
  min-width: 50px;
  text-align: right;
}
.filed .font12[data-v-555edaca] {
  font-size: 12px;
  margin: 0 10px;
  cursor: pointer;
}
.filed .el-input[data-v-555edaca] {
  width: 170px;
}
.filed .w100[data-v-555edaca] {
  width: 100px;
}
.filed .w120[data-v-555edaca] {
  width: 120px;
}
.filed .w70[data-v-555edaca] {
  width: 70px;
}
.filed .mar5[data-v-555edaca] {
  margin-left: -5px;
}
.filed[data-v-555edaca] .el-input-number__decrease,
.filed[data-v-555edaca] .el-input-number__increase {
  width: 20px;
  height: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: none;
}
.filed[data-v-555edaca] .el-input-number__decrease:hover {
  height: 16px;
}
.filed[data-v-555edaca] .el-input-number__decrease:hover + .el-input-number__increase {
  height: 8px;
}
.filed[data-v-555edaca] .el-input-number__increase:hover {
  height: 16px;
}
.filed[data-v-555edaca] .el-input-number__increase:hover + .el-input-number__decrease {
  height: 8px;
}
.filed .de-bottom-line[data-v-555edaca] {
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  word-wrap: break-word;
  text-align: left;
  line-height: 28px;
  color: #7e7e7e;
  font-size: 12px;
  white-space: pre;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 1px;
  background-color: #000;
  opacity: 0.3;
  position: absolute;
  right: 5px;
  bottom: 9px;
  width: 100px;
  z-index: 10;
}
.filed .short[data-v-555edaca] {
  width: 83px;
  right: 22px;
}
.filed[data-v-555edaca] .el-input-number.is-controls-right .el-input__inner {
  padding-right: 20px;
}
.filed .el-input-number[data-v-555edaca] {
  line-height: 26px;
  height: 27px;
}
.filed[data-v-555edaca] .el-input__inner {
  background-color: #f8f8fa;
  border: none;
  border-radius: 0;
  height: 26px;
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, sans-serif;
  word-wrap: break-word;
  text-align: left;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";
  list-style: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  line-height: 26px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: 1;
}
.filed i[data-v-555edaca] {
  margin-left: 5px;
  color: #7e7e7e;
}
.filed[data-v-555edaca]:hover {
  background-color: #e9eaef;
}
.filed[data-v-555edaca]:hover .el-input-number__decrease,
.filed[data-v-555edaca]:hover .el-input-number__increase {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}