.dataset-on-time[data-v-397f46e7] {
  margin: 0;
  width: 100%;
  overflow: auto;
  background-color: var(--ContentBG, #fff);
  padding: 24px;
  height: 100%;
}
.dataset-on-time[data-v-397f46e7] .el-link--inner {
  color: var(--primary, #3370ff) !important;
}
.dataset-on-time .el-icon-more[data-v-397f46e7] {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 12px;
  color: var(--primary, #3370ff);
}
.dataset-on-time .el-icon-more[data-v-397f46e7]:hover {
  background: var(--deWhiteHover, #3370ff);
  border-radius: 4px;
}
.dataset-on-time .el-icon-more[data-v-397f46e7]:active {
  background: var(--deWhiteActive, #3370ff);
  border-radius: 4px;
}
.table-container[data-v-397f46e7] {
  height: calc(100% - 50px);
}
.table-container .mar6[data-v-397f46e7] {
  margin-right: 6px;
}
.table-container .mar3[data-v-397f46e7] {
  margin-left: -3px;
}
.table-container-filter[data-v-397f46e7] {
  height: calc(100% - 110px);
}