.de-ds-container[data-v-6713d568] {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.de-ds-container .el-empty[data-v-6713d568] {
  padding-top: 202px;
}
.ms-aside-container[data-v-6713d568] {
  height: calc(100vh - 56px);
  padding: 0px;
  min-width: 260px;
  max-width: 460px;
}
.dsr-route-title[data-v-6713d568] {
  width: 100%;
  margin: -2px 0 22px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: center;
      align-content: center;
}
.is-driver-mgm[data-v-6713d568] {
  height: calc(100vh - 56px);
  background-color: var(--MainBG, #f5f6f7);
  padding: 24px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.is-driver-mgm .ms-aside-container[data-v-6713d568],
.is-driver-mgm .ms-main-container[data-v-6713d568] {
  height: calc(100vh - 170px);
  background-color: var(--ContentBG, #ffffff);
}
.is-driver-mgm .ms-aside-container .tree-style[data-v-6713d568],
.is-driver-mgm .ms-main-container .tree-style[data-v-6713d568] {
  padding-top: 24px;
}
.is-driver-mgm .ms-main-container[data-v-6713d568] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  position: relative;
  padding: 24px 0 70px 24px;
  background: #fff;
}