.form-tree-cont .tree-cont {
  height: 200px;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--deBorderBase, #DCDFE6);
  overflow: auto;
}
.form-tree-cont .tree-cont .content {
  height: 100%;
  width: 100%;
}
.icon-class {
  color: #6c6c6c;
}
.blackTheme .icon-class {
  color: #cccccc;
}
.tip {
  color: #F56C6C;
  font-size: 12px;
}