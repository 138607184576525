.sql-dataset-drawer .el-drawer__body {
  padding: 16px 24px;
  position: unset;
  overflow-y: auto;
  padding-bottom: 80px;
}
.sql-dataset-drawer .el-date-editor {
  width: 100%;
}
.sql-dataset-drawer .select-type {
  width: 180px;
}
.sql-dataset-drawer .select-type .el-input__inner {
  padding-left: 32px;
}
.sql-dataset-drawer .select-svg-icon {
  position: absolute;
  left: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.sql-dataset-drawer .content {
  height: 62px;
  width: 822px;
  border-radius: 4px;
  background: #e1eaff;
  position: relative;
  padding: 9px 0 9px 40px;
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
}
.sql-dataset-drawer .content i {
  position: absolute;
  top: 12.6px;
  left: 16.7px;
  font-size: 14px;
  color: var(--primary, #3370ff);
}
.dataset-sql {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.dataset-sql .sql-editer {
  background: #f5f6f7;
  padding: 16px 24px;
}
.dataset-sql .reference-sql-table {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  overflow: hidden;
}
.dataset-sql .reference-sql-table .data-reference {
  width: 280px;
  height: 100%;
  overflow: hidden;
  border-left: 1px solid var(--deCardStrokeColor, #dee0e3);
}
.dataset-sql .reference-sql-table .data-reference .no-select-datasource {
  font-family: PingFang SC;
  font-size: 14px;
  color: var(--deTextPrimary, #1f2329);
  font-weight: 400;
  display: inline-block;
  width: 100%;
  padding: 16px 12px;
}
.dataset-sql .reference-sql-table .data-reference .table-database-name {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  color: var(--deTextPrimary, #1f2329);
  padding: 16px 12px;
  border-bottom: 1px solid var(--deCardStrokeColor, #dee0e3);
}
.dataset-sql .reference-sql-table .data-reference .table-database-name p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0;
}
.dataset-sql .reference-sql-table .data-reference .table-database-name .grey-name {
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.dataset-sql .reference-sql-table .data-reference .table-database-name .grey {
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextSecondary, #646a73);
}
.dataset-sql .reference-sql-table .data-reference .item-list {
  padding: 16px 8px;
  height: calc(100vh - 242px);
  overflow: auto;
}
.dataset-sql .reference-sql-table .data-reference .item-list .table-or-field {
  height: 40px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 4px;
  color: var(--primary, #3370ff);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 4px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.dataset-sql .reference-sql-table .data-reference .item-list .table-or-field i {
  display: none;
  cursor: pointer;
}
.dataset-sql .reference-sql-table .data-reference .item-list .table-or-field .name {
  cursor: pointer;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dataset-sql .reference-sql-table .data-reference .item-list .table-or-field.field {
  color: var(--deTextPrimary, #1f2329);
}
.dataset-sql .reference-sql-table .data-reference .item-list .table-or-field.field:hover {
  background: none;
}
.dataset-sql .reference-sql-table .data-reference .item-list .table-or-field:hover {
  background: rgba(31, 35, 41, 0.1);
}
.dataset-sql .reference-sql-table .data-reference .item-list .table-or-field:hover i {
  display: block;
}
.dataset-sql .reference-sql-table .sql-table {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: hidden;
}
.dataset-sql .reference-sql-table .sql-table .code-container {
  background: #f5f6f7;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  min-height: 248px;
  color: var(--deTextPrimary, #1f2329);
}
.dataset-sql .reference-sql-table .sql-table .code-container .CodeMirror {
  height: 100% !important;
}
.dataset-sql .sql-result {
  font-family: PingFang SC;
  font-size: 14px;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}
.dataset-sql .sql-result .sql-title {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 16px 24px;
  font-weight: 500;
  position: relative;
  color: var(--deTextPrimary, #1f2329);
  border-bottom: 1px solid rgba(31, 35, 41, 0.15);
}
.dataset-sql .sql-result .sql-title .result-num {
  font-weight: 400;
  color: var(--deTextSecondary, #646a73);
  margin-left: 12px;
}
.dataset-sql .sql-result .sql-title .drag {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 7px;
  width: 100px;
  border-radius: 3.5px;
  background: rgba(31, 35, 41, 0.1);
  cursor: row-resize;
}
.dataset-sql .sql-result .padding-24 .el-tabs__nav-scroll {
  padding-left: 24px;
}
.dataset-sql .sql-result .table-sql {
  height: calc(100% - 110px);
  padding: 0 25px 18px 25px;
  overflow-y: auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.dataset-sql .sql-result .table-sql .el-empty__bottom,
.dataset-sql .sql-result .table-sql .el-empty__description p {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 400;
  margin-top: 0;
  color: var(--deTextSecondary, #646a73);
}
.dataset-sql .table-container {
  height: calc(100% - 125px);
  padding: 0 24px;
}
.dataset-sql .table-container .mar6 {
  margin-right: 6px;
}
.dataset-sql .table-container .mar3 {
  margin-left: -3px;
}
.dataset-sql .table-container-filter {
  height: calc(100% - 110px);
}