.view-table[data-v-e1135916] {
  padding: 10px 9px;
  height: 100%;
  overflow-y: hidden;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.view-table .de-dataset-name[data-v-e1135916] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: PingFang SC;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
}
.view-table .de-dataset-name .title-text[data-v-e1135916] {
  font-size: 16px;
  font-weight: 500;
  margin-right: 8px;
  color: var(--deTextPrimary, #1f2329);
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 50%;
}
.view-table .de-dataset-name .de-tag[data-v-e1135916] {
  padding: 0 6px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
}
.view-table .de-dataset-name .warning[data-v-e1135916] {
  color: var(--deWarning, #ff8800);
  background: rgba(255, 136, 0, 0.2);
}
.view-table .de-dataset-name .primary[data-v-e1135916] {
  color: var(--primary, #3370ff);
  background: rgba(51, 112, 255, 0.2);
}
.view-table .de-dataset-name .el-divider[data-v-e1135916] {
  margin: 0 16px;
  height: 18px;
}
.view-table .de-dataset-name .create-by[data-v-e1135916] {
  font-size: 14px;
  font-weight: 400;
  color: var(--deTextSecondary, #606266);
}
.view-table .de-dataset-name .detail[data-v-e1135916] {
  cursor: pointer;
  margin-left: 10px;
  color: var(--deTextSecondary, #606266);
}